
































import { Component, Prop, Vue } from "vue-property-decorator";
import EventsList from "@/components/widgets/events/EventsList.vue";
import {
  EventsQueryVariables,
  InputEventsEnum,
  SqlOperator,
  User,
  Event,
  Maybe,
  ParticipantStatusEnum,
  UpdateEventMutation,
  UpdateEventMutationVariables,
  UpdateEventParticipants,
  QueryEventsOrderByColumn,
  SortOrder,
} from "@/generated/graphql";
import { TableHeader } from "@components/parts/tables/Settings.vue";
import { participantStatuses } from "@/core/static/dict";
import { UpdateEvent } from "@/graphql/queries/Events.graphql";

@Component({
  name: "EventList",
  components: {
    EventsList,
  },
})
export default class UserEvents extends Vue {
  @Prop({ type: String, default: null }) userId!: string;

  public $refs!: {
    table: EventsList;
  };

  protected readonly participantStatuses = participantStatuses;

  protected extraHeaders: TableHeader[] = [
    {
      value: "role",
      text: "Роль",
      sortable: false,
    },
    {
      value: "status_user",
      text: "Статус пользователя",
      sortable: false,
    },
  ];

  protected events = [];
  protected eventsSort = [
    { column: QueryEventsOrderByColumn.DateFrom, order: SortOrder.Desc },
  ];

  /**
   * Extra параметры смешиватся с параметрами запроса внутри виджета
   * и имеют наивысший приоритет, экстра параметры могут перезаписать внутренние параметры
   * standalone виджета
   * @protected
   */
  protected get extraFilterParams(): Partial<EventsQueryVariables> {
    return {
      where: {
        column: InputEventsEnum.IsArchive,
        operator: SqlOperator.In,
        value: [true, false],
        AND: [
          {
            OR: [
              {
                HAS: {
                  relation: "managers",
                  condition: {
                    column: InputEventsEnum.Id,
                    operator: SqlOperator.In,
                    value: [this.userId],
                  },
                },
              },
              {
                HAS: {
                  relation: "lecturers",
                  condition: {
                    column: InputEventsEnum.Id,
                    operator: SqlOperator.In,
                    value: [this.userId],
                  },
                },
              },
              {
                HAS: {
                  relation: "anchorpersons",
                  condition: {
                    column: InputEventsEnum.Id,
                    operator: SqlOperator.In,
                    value: [this.userId],
                  },
                },
              },
              {
                HAS: {
                  relation: "producers",
                  condition: {
                    column: InputEventsEnum.Id,
                    operator: SqlOperator.In,
                    value: [this.userId],
                  },
                },
              },
              {
                HAS: {
                  relation: "participants",
                  condition: {
                    column: InputEventsEnum.Id,
                    operator: SqlOperator.In,
                    value: [this.userId],
                  },
                },
              },
            ],
          },
        ],
      },
    };
  }

  /**
   *
   * @protected
   */
  protected getUserRoles(event: Event): string[] {
    const roles = [];

    if (event.participants && this.getUser(event.participants)) {
      roles.push("Участник");
    }
    if (event.lecturers && this.getUser(event.lecturers)) {
      roles.push("Лектор");
    }
    if (event.managers && this.getUser(event.managers)) {
      roles.push("Менеджер");
    }
    if (event.producers && this.getUser(event.producers)) {
      roles.push("Режиссер");
    }
    if (event.anchorpersons && this.getUser(event.anchorpersons)) {
      roles.push("Ведущий");
    }

    return roles;
  }

  protected getUser(users: User[]): User | undefined {
    return users?.find((user) => user.id === this.userId);
  }

  protected getParticipantStatus(event: Event): Maybe<string | undefined> {
    const user = event.participants ? this.getUser(event.participants) : null;
    return user?.pivot && user?.pivot?.participant_status
      ? user.pivot.participant_status || ""
      : null;
  }

  protected participantStatusChangeLoading = false;
  protected async changeUserStatus(
    event: Event,
    status: ParticipantStatusEnum
  ): Promise<void> {
    const user = event.participants ? this.getUser(event.participants) : null;
    if (!user) return;

    const newParticipantsInput: UpdateEventParticipants = {
      sync: event.participants?.map((participant) => {
        return {
          id: participant.id,
          participant_status:
            participant.id === this.userId
              ? status
              : participant.pivot?.participant_status,
        };
      }),
    };

    try {
      this.participantStatusChangeLoading = true;
      await this.$apollo.mutate<
        UpdateEventMutation,
        UpdateEventMutationVariables
      >({
        mutation: UpdateEvent,
        variables: {
          input: {
            id: event.id,
            participants: newParticipantsInput,
          },
        },
      });

      await this.$refs.table.refetch();
    } finally {
      this.participantStatusChangeLoading = false;
    }
  }
}
