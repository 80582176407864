var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('EventsTable',_vm._b({attrs:{"items":_vm.list.data,"search-value":_vm.filter.search,"show-select":_vm.showSelect,"disable-export":_vm.standalone,"extra-headers":_vm.extraHeaders},on:{"update:searchValue":function($event){return _vm.$set(_vm.filter, "search", $event)},"update:search-value":function($event){return _vm.$set(_vm.filter, "search", $event)},"pagination":_vm.paginate},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_vm._t("actions")]},proxy:true},_vm._l((_vm.extraHeaders),function(header){return {key:("item." + (header.value)),fn:function(ref){
var item = ref.item;
return [_vm._t(("item." + (header.value)),function(){return [_vm._v(" "+_vm._s(item[header.value])+" ")]},null,{ item: item })]}}}),(!_vm.noFilters)?{key:"filters",fn:function(){return [_c('v-row',{staticClass:"mb-4",attrs:{"justify":"end"}},[_c('v-text-field',{staticStyle:{"max-width":"500px"},attrs:{"prepend-inner-icon":"mdi-magnify","label":"Поиск","hide-details":""},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}})],1),_c('v-row',[_c('v-col',{attrs:{"md":"2"}},[_c('v-select',{attrs:{"label":"Форма проведения","multiple":"","chips":"","clearable":"","items":_vm.eventForms},on:{"change":_vm.refetch},model:{value:(_vm.filter.eventForms),callback:function ($$v) {_vm.$set(_vm.filter, "eventForms", $$v)},expression:"filter.eventForms"}})],1),_c('v-col',{attrs:{"md":"2"}},[_c('v-select',{attrs:{"multiple":"","deletable-chips":"","chips":"","clearable":"","label":"Категории","items":[
            { text: 'Одиночные', value: 1 },
            { text: 'Циклы', value: 2 } ]},on:{"change":_vm.refetch},model:{value:(_vm.filter.categories),callback:function ($$v) {_vm.$set(_vm.filter, "categories", $$v)},expression:"filter.categories"}})],1),_c('v-col',{attrs:{"md":"2"}},[_c('CycleEventsAutocomplete',{attrs:{"chips":"","deletable-chips":"","multiple":"","clearable":""},on:{"change":_vm.refetch},model:{value:(_vm.filter.cycles),callback:function ($$v) {_vm.$set(_vm.filter, "cycles", $$v)},expression:"filter.cycles"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('v-row',{attrs:{"justify":"end","align":"center"}},[_c('div',{staticClass:"mt-5"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"red","text":""},on:{"click":function($event){_vm.filter = Object.assign({}, _vm.initialFilter);
                _vm.additionalFilter = Object.assign({}, _vm.initialAdditionalFilters);
                _vm.refetch();}}},[_vm._v(" сбросить ")]),_c('v-dialog',{attrs:{"max-width":"500","width":"100%","scrollable":"","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","outlined":""}},on),[_vm._v(" еще фильтры ")])]}}],null,false,771208371),model:{value:(_vm.additionalFiltersVisible),callback:function ($$v) {_vm.additionalFiltersVisible=$$v},expression:"additionalFiltersVisible"}},[_c('v-card',[_c('v-card-title',[_vm._v("Дополнительные фильтры")]),_c('v-divider'),_c('v-card-text',[_c('v-select',{attrs:{"label":"Тип оплаты","items":_vm.paymentTypes,"multiple":"","clearable":""},model:{value:(_vm.additionalFilter.paymentType),callback:function ($$v) {_vm.$set(_vm.additionalFilter, "paymentType", $$v)},expression:"additionalFilter.paymentType"}}),_c('v-select',{attrs:{"label":"Статус","items":_vm.eventStatuses,"multiple":"","clearable":""},model:{value:(_vm.additionalFilter.eventStatus),callback:function ($$v) {_vm.$set(_vm.additionalFilter, "eventStatus", $$v)},expression:"additionalFilter.eventStatus"}}),_c('v-text-field',{attrs:{"label":"Дата начала","type":"date","clearable":""},model:{value:(_vm.additionalFilter.dateFrom),callback:function ($$v) {_vm.$set(_vm.additionalFilter, "dateFrom", $$v)},expression:"additionalFilter.dateFrom"}}),_c('v-text-field',{attrs:{"label":"Дата окончания","type":"date","clearable":""},model:{value:(_vm.additionalFilter.dateTo),callback:function ($$v) {_vm.$set(_vm.additionalFilter, "dateTo", $$v)},expression:"additionalFilter.dateTo"}}),_c('SpecialtiesAutocomplete',{attrs:{"multiple":"","small-chips":"","deletable-chips":"","clearable":""},model:{value:(_vm.additionalFilter.specialties),callback:function ($$v) {_vm.$set(_vm.additionalFilter, "specialties", $$v)},expression:"additionalFilter.specialties"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.additionalFiltersVisible = false;
                      _vm.refetch();}}},[_vm._v(" Готово ")]),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){_vm.additionalFilter = Object.assign({}, _vm.initialAdditionalFilters);
                      _vm.additionalFiltersVisible = false;
                      _vm.refetch();}}},[_vm._v(" сбросить ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.additionalFiltersVisible = false}}},[_vm._v("отменить")])],1)],1)],1)],1)])],1)],1)]},proxy:true}:null,{key:"item._actions",fn:function(ref){
                      var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","to":{ name: _vm.Routes.eventID, params: { id: item.id } }}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},'EventsTable',_vm.tableBindings,false))}
var staticRenderFns = []

export { render, staticRenderFns }