














































import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
  name: "UserQRCode",
})
export default class extends Vue {
  @Prop(Number) id!: number;
}
