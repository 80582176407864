import {
  Actions,
  Context,
  Getters,
  Module,
  Mutations,
} from "vuex-smart-module";
import { apolloClient } from "@/core/apollo";
import {
  MeQuery,
  MutationLoginArgs,
  User,
  PermissionsEnum,
  Permission,
} from "@/generated/graphql";
import { ApolloQueryResult } from "apollo-boost";
import { Login, Me } from "@/graphql/queries/User.graphql";
import { Logout } from "@/graphql/queries/Logout.graphql";
import { root } from "./index";
import { Store } from "vuex";

const ADMIN: string = "administrator";

class SessionState {
  me: User = {} as User;
  logged: boolean = false;
  permissions: Array<PermissionsEnum> = [];
}

class SessionGetters extends Getters<SessionState> {
  root!: Context<typeof root>;

  // Called after the module is initialized
  $init(store: Store<any>): void {
    // Create and retain foo module context
    this.root = root.context(store);
  }

  get me(): User | null {
    return this.state.me;
  }

  get isAdmin(): boolean {
    if (!this.getters.logged) return false;
    return !!this.getters.me?.roles?.find((role) => role.name === ADMIN);
  }

  isAllow(permit: PermissionsEnum): boolean {
    if (!this.getters.logged) return false;
    return this.getters.me?.roles?.find((role) => role.name === ADMIN)
      ? true
      : this.getters.permissions.includes(permit);
  }

  get nameInitials(): string {
    if (
      !this.state.me ||
      !this.state.me.first_name ||
      !this.state.me?.family_name
    ) {
      return "";
    }
    return [this.state.me?.first_name[0], this.state.me?.family_name[0]]
      .join("")
      .trim()
      .toUpperCase();
  }

  get fullName(): string {
    return this.root.getters.parseUserFullName(this.state.me);
  }
  get logged(): boolean {
    return this.state.logged;
  }
  get permissions(): Array<PermissionsEnum> {
    return this.state.permissions;
  }
}

class SessionMutations extends Mutations<SessionState> {
  setMe(me: User) {
    this.state.me = me;
  }
  setLogged(logged: boolean) {
    this.state.logged = logged;
  }
  setPermissions(permissions: Array<PermissionsEnum>) {
    this.state.permissions = permissions;
  }
}

class SessionActions extends Actions<
  SessionState,
  SessionGetters,
  SessionMutations,
  SessionActions
> {
  async getMe(): Promise<ApolloQueryResult<MeQuery>> {
    const result = await apolloClient.query<MeQuery>({
      query: Me,
      fetchPolicy: "no-cache",
    });

    if (result.errors) throw new Error(result.errors[0].message);
    if (result.data) {
      this.commit("setMe", result.data.me as User);
      const permissions = result.data.me?.roles?.reduce(
        (previousValue: PermissionsEnum[], role) => {
          const names = role.permissions.map((perm: Permission) => perm.name);
          return [...previousValue, ...names];
        },
        []
      ) as PermissionsEnum[];
      this.commit("setPermissions", permissions as PermissionsEnum[]);
      this.commit("setLogged", true);
    }
    return result;
  }

  async login({ login, password, remember }: MutationLoginArgs) {
    const { data } = await apolloClient.mutate({
      mutation: Login,
      variables: {
        login,
        password,
        remember,
      },
    });
    await this.dispatch("getMe");
    if (data.login) this.commit("setLogged", true);
    return data;
  }

  async logout() {
    const result = await apolloClient.mutate({
      mutation: Logout,
    });
    if (result?.data.logout) {
      this.commit("setLogged", false);
    }

    return result;
  }
}

export default new Module({
  state: SessionState,
  mutations: SessionMutations,
  getters: SessionGetters,
  actions: SessionActions,
});
