var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg pa-4",attrs:{"elevation":"0"}},[(_vm.loading)?_c('div',_vm._l((5),function(i){return _c('div',{key:i},[_c('v-skeleton-loader',{attrs:{"type":"text","max-width":"100"}}),_c('v-skeleton-loader',{staticClass:"mb-4",attrs:{"type":"card","height":"35"}})],1)}),0):_c('div',[_c('v-text-field',{staticClass:"mb-4",attrs:{"label":"Фамилия","hint":"Обязательно","persistent-hint":"","rules":[
        _vm.rules.required,
        _vm.rules.min(2),
        _vm.rules.max(255),
        _vm.rules.alpha('ru') ]},model:{value:(_vm.syncedFamilyName),callback:function ($$v) {_vm.syncedFamilyName=$$v},expression:"syncedFamilyName"}}),_c('v-text-field',{staticClass:"mb-4",attrs:{"label":"Имя","hint":"Обязательно","persistent-hint":"","rules":[
        _vm.rules.required,
        _vm.rules.min(2),
        _vm.rules.max(255),
        _vm.rules.alpha('ru') ]},model:{value:(_vm.syncedFirstName),callback:function ($$v) {_vm.syncedFirstName=$$v},expression:"syncedFirstName"}}),_c('v-text-field',{staticClass:"mb-6",attrs:{"label":"Отчество","rules":[_vm.rules.min(2), _vm.rules.max(255), _vm.rules.alpha('ru')]},model:{value:(_vm.syncedSecondName),callback:function ($$v) {_vm.syncedSecondName=$$v},expression:"syncedSecondName"}}),_c('DatePicker',{attrs:{"hide-details":false,"rules":[_vm.rules.required],"label":"Дата рождения*","dateFormat":"dd.MM.yyyy","readonly":true,"clearable":false},model:{value:(_vm.syncedBD),callback:function ($$v) {_vm.syncedBD=$$v},expression:"syncedBD"}}),_c('CityAutocomplete',{attrs:{"rules":[_vm.rules.required]},model:{value:(_vm.syncedCity),callback:function ($$v) {_vm.syncedCity=$$v},expression:"syncedCity"}}),_c('v-radio-group',{attrs:{"row":"","hint":"Обязательно","persistent-hint":"","rules":[_vm.rules.required]},model:{value:(_vm.syncedSex),callback:function ($$v) {_vm.syncedSex=$$v},expression:"syncedSex"}},[_c('v-radio',{attrs:{"label":"Мужчина","value":_vm.SexEnum.Male}}),_c('v-radio',{attrs:{"label":"Женщина","value":_vm.SexEnum.Female}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }