












import { Vue, Component, Prop } from "vue-property-decorator";
import XLSX, { WorkBook } from "xlsx";
import { DataTableHeader } from "vuetify";

@Component
export default class ExcelExport extends Vue {
  @Prop({ type: Array, default: () => [] }) headers!: DataTableHeader[];
  @Prop({ type: Array, default: () => ["_actions"] })
  exportIgnoreHeaders!: string[];
  @Prop({ type: Array, default: () => [] }) data!: Array<{
    [key: string]: unknown;
  }>;
  @Prop({ type: Object, default: () => ({}) }) resolvers!: Record<
    string,
    CallableFunction
  >;

  /**
   * Очистка от заголовков указанных в export ignore headers
   * @protected
   */
  protected get cleanHeaders(): DataTableHeader[] {
    return this.headers.filter(
      (header) => !this.exportIgnoreHeaders.includes(header.value)
    );
  }

  /**
   * Формирование структуры файла Excel
   * @protected
   */
  protected generateExcelWorkbook(): void {
    const workbook = XLSX.utils.book_new();
    const SHEET_NAME = "Doctor School экспорт";
    workbook.Props = { Title: "DCS" };
    workbook.SheetNames.push(SHEET_NAME);

    const sheet = XLSX.utils.aoa_to_sheet(this.dataset);
    workbook.Sheets[SHEET_NAME] = sheet;

    // Установка первым 64 кононкам ширину в 20 символов
    const wscols = new Array(64).fill({ wch: 20 });
    sheet["!cols"] = wscols;

    this.download(workbook);
  }

  /**
   * Скачивание файла
   *
   * @param workbook
   * @protected
   */
  protected download(workbook: WorkBook): void {
    XLSX.writeFile(workbook, "file.xlsx");
  }

  /**
   * Array of Arrays формат для записи в файл
   * Массив строк, в каждой массив ячеек
   *
   * Тут происходит сопоставление данных колонки к ее порядковому номеру
   * соответствующего заголовка
   * @protected
   */
  protected get dataset(): Array<unknown>[] {
    const headersRow = this.cleanHeaders.map((header) => header.text);
    let result: Array<unknown[]> = [];
    this.data.forEach((item) => {
      const row: unknown[] = [];
      this.headers.forEach((header, index) => {
        const itemRawValue = item[header.value];
        const itemValue = this.resolvers[header.value]
          ? this.resolvers[header.value](item)
          : itemRawValue;

        row.splice(index, 0, itemValue);
      });

      result.push(row);
    });

    return [
      [...headersRow],
      [], // divider,
      ...result,
    ];
  }
}
