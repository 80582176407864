






























































import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import { User } from "@/generated/graphql";

import UserCard from "@widgets/user/UserCard.vue";
import UserAbout from "@widgets/user/UserAbout.vue";
import UserContacts from "@widgets/user/UserContacts.vue";
import UserComment from "@widgets/user/UserComment.vue";
import { required, phone, email, urlLink } from "@/core/validation";

@Component({
  components: {
    UserCard,
    UserAbout,
    UserContacts,
    UserComment,
  },
  methods: {
    required,
    phone,
    email,
    urlLink,
  },
})
export default class UserCommonProfile extends Vue {
  @PropSync("family_name", { required: true, type: String })
  syncFamilyName!: User["family_name"];

  @PropSync("birth_date", { type: [String], default: () => undefined })
  syncBirthDate!: User["birth_date"];

  @PropSync("city", { default: () => undefined, type: [Object] })
  syncCity!: User["city"];

  @PropSync("comment", { type: [String], default: () => null })
  syncComment!: User["comment"];

  @PropSync("emails", { type: [Array], default: () => null })
  syncEmails!: User["emails"];

  @PropSync("phones", { type: [Array], default: () => null })
  syncPhones!: User["phones"];

  @PropSync("sites", { type: [Array], default: () => null })
  syncSites!: User["sites"];

  @PropSync("second_name", { type: [String], default: () => undefined })
  syncSecondName!: User["second_name"];

  @PropSync("first_name", { type: String, required: true })
  syncFirstName!: User["first_name"];

  @PropSync("avatar", { type: [String, File], default: () => undefined })
  syncAvatar!: User["avatar"] | File;

  @PropSync("roles", { type: [Array], default: () => undefined })
  syncRoles!: User["roles"];

  @PropSync("sex", { type: [String, Number], default: () => undefined })
  syncSex!: User["sex"];

  @Prop({ type: Boolean, default: false }) loading!: boolean;
}
