





























import { Component, Vue, VModel, Prop } from "vue-property-decorator";
import { AllDoctorSpecialities } from "@/graphql/queries/DoctorsSpecialities.graphql";
import { AllEmployeesSpecialties } from "@/graphql/queries/EmployeesSpecialities.graphql";
import {
  DoctorSpecialty,
  EmployeeSpecialty,
  QueryAllDoctorsSpecialtiesOrderByColumn,
  SortOrder,
} from "@/generated/graphql";
import { DocumentNode } from "graphql";

@Component
export default class SpecialtiesAutocomplete extends Vue {
  @Prop({ type: Boolean, default: false }) employees!: boolean;

  @VModel({ type: [String, Array] }) model!:
    | DoctorSpecialty
    | DoctorSpecialty[]
    | EmployeeSpecialty
    | EmployeeSpecialty[];

  protected search: string = "";
  protected get query(): DocumentNode {
    return this.employees ? AllEmployeesSpecialties : AllDoctorSpecialities;
  }

  protected get variables(): any {
    return this.employees
      ? {}
      : {
          orderBy: [
            {
              column: QueryAllDoctorsSpecialtiesOrderByColumn.Name,
              order: SortOrder.Asc,
            },
          ],
        };
  }
}
