import { Component, Vue } from "vue-property-decorator";
import PageSection from "@components/page/Section.vue";
import PageCard from "@components/page/Card.vue";
import Card from "@components/page/Card.vue";
import PageControlsBar from "@components/page/ControlsBar.vue";

@Component({
  name: "Page",
  components: { PageSection, PageCard, Card, PageControlsBar },
})
export class BaseView extends Vue {
  protected ready: boolean = false;

  async mounted(): Promise<void> {
    try {
      await this.onPageEnter();
    } catch (e) {
      console.error(e);
    } finally {
      this.ready = true;
    }
  }

  /**
   * Use instead beforeRouteEnter!
   * vue-router 'beforeRouteEnter' hook has no access to this
   */
  onPageEnter(): void | Promise<void> {
    return;
  }
}
