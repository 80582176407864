var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Table',_vm._g(_vm._b({attrs:{"items":_vm.filteredEvents,"headers":_vm.headers,"export-resolvers":_vm.exportResolvers,"show-select":_vm.showSelect,"visible-columns":_vm.visibleHeaders},on:{"update:visibleColumns":[function($event){_vm.visibleHeadersList = $event.map(function (h) { return h.value; })},function($event){_vm.visibleHeaders=$event}],"update:visible-columns":function($event){_vm.visibleHeaders=$event}},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [_c('tr',_vm._l((_vm.visibleHeaders),function(header){return _c('td',{key:header.value},[(header.value !== '_actions')?_c('v-text-field',{attrs:{"type":header.value.includes('date') ? 'date' : 'text',"placeholder":header.text},model:{value:(_vm.filter[header.value]),callback:function ($$v) {_vm.$set(_vm.filter, header.value, $$v)},expression:"filter[header.value]"}}):_vm._e()],1)}),0)]},proxy:true},(_vm.$slots.actions)?{key:"actions",fn:function(){return [_vm._t("actions")]},proxy:true}:null,{key:"filters",fn:function(){return [_vm._t("filters")]},proxy:true},_vm._l((_vm.headers),function(header){return {key:("item." + (header.value)),fn:function(ref){
var item = ref.item;
return [_vm._t(("item." + (header.value)),function(){return [_vm._v(" "+_vm._s(item[header.value])+" ")]},null,{ item: item })]}}}),{key:"item.public_name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: _vm.Routes.eventID, params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.public_name)+" ")])]}},{key:"item.event_form",fn:function(ref){
var item = ref.item;
return [(item.event_form === _vm.EventFormEnum.Online)?_c('v-chip',{attrs:{"small":"","color":"primary lighten-4 black--text"}},[_vm._v(" "+_vm._s(_vm.eventFormsNames[item.event_form])+" ")]):(item.event_form === _vm.EventFormEnum.Offline)?_c('v-chip',{attrs:{"small":"","color":"orange lighten-4"}},[_vm._v(" "+_vm._s(_vm.eventFormsNames[item.event_form])+" ")]):_c('v-chip',{attrs:{"small":"","color":"brown lighten-4"}},[_vm._v(" "+_vm._s(_vm.eventFormsNames[item.event_form])+" ")])]}},{key:"item.payment_type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(item.payment_type === _vm.PaymentTypeEnum.Paid)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"orange"}},[_vm._v("mdi-currency-rub")]):_vm._e(),_vm._v(" "+_vm._s(_vm.paymentTypesNames[item.payment_type])+" ")],1)]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [(item.payment_type === _vm.PaymentTypeEnum.Paid)?_c('span',[_vm._v(_vm._s(_vm._f("currency")(item.price)))]):_c('span',[_vm._v(" - ")])]}},{key:"item.specialties",fn:function(ref){
var item = ref.item;
return [(item.specialties && item.specialties.length)?_c('div',{staticClass:"my-2"},_vm._l((_vm.eventSpecialtiesItem(item)),function(spec){return _c('v-chip',{key:spec.id,staticClass:"mr-1 my-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(spec.name)+" ")])}),1):_c('div',{staticClass:"v-data-table__empty-wrapper text-left"},[_vm._v(" Нет специальностей ")])]}},{key:"item.event_status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[(item.event_status === _vm.EventStatusEnum.Accredited)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check-circle-outline ")]):(item.event_status === _vm.EventStatusEnum.NotAccredited)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close-circle-outline ")]):(item.event_status === _vm.EventStatusEnum.Awaiting)?_c('v-icon',{attrs:{"color":"orange"}},[_vm._v(" mdi-clock-outline ")]):_vm._e(),_c('span',{staticClass:"ml-2 mt-2"},[_vm._v(" "+_vm._s(_vm.eventStatusesNames[item.event_status])+" ")])],1)]}},{key:"item.date_from",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.date_from))+" ")]}},{key:"item.date_to",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.date_to))+" ")]}},{key:"item.city",fn:function(ref){
var item = ref.item;
return [(item.city)?_c('span',[_vm._v(_vm._s(item.city.name))]):_c('div',{staticClass:"v-data-table__empty-wrapper text-left"},[_vm._v(" нет города ")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [(item.date_from)?_c('span',[_vm._v(_vm._s(_vm._f("date")(item.date_from)))]):_vm._e()]}},{key:"item.amount_nmo",fn:function(ref){
var item = ref.item;
return [(item.amount_nmo > 0)?_c('v-chip',{attrs:{"color":"green lighten-4"}},[_vm._v(" "+_vm._s(item.amount_nmo)+" баллов НМО ")]):_c('div',{staticClass:"v-data-table__empty-wrapper text-left"},[_vm._v(" нет баллов ")])]}},{key:"item._actions",fn:function(ref){
var item = ref.item;
return [_vm._t("item._actions",null,null,{ item: item })]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},'Table',_vm.$attrs,false),_vm.$listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }