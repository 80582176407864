

































































































































































































import {
  Component,
  Emit,
  Prop,
  VModel,
  Vue,
  Watch,
} from "vue-property-decorator";
import {
  AllDoctorWorkplaces,
  createDoctorWorkplace,
} from "@/graphql/queries/DoctorsWorkplaces.graphql";
import {
  AllDoctorWorkplacesQuery,
  DoctorWorkplace,
  SyncInput,
} from "@/generated/graphql";
import { hospitalNames, hospitalTypes } from "@/core/static/dict";
import { ruMessages } from "@/plugins/vee-validate";
import * as rules from "@/core/validation";

@Component({
  apollo: {
    allDoctorsWorkplaces: {
      query: AllDoctorWorkplaces,
      skip: true,
    },
  },
})
export default class UserWorkplaces extends Vue {
  @VModel({ type: Array, default: () => [] }) workplaces!: DoctorWorkplace[];
  @Prop({ type: Boolean, default: false }) loading!: boolean;

  readonly rules = rules;

  protected readonly hospitalNames = hospitalNames;
  protected formVisible = false;
  protected allDoctorsWorkplaces: AllDoctorWorkplacesQuery["allDoctorsWorkplaces"] =
    [];

  protected formWorkplacesValue: DoctorWorkplace[] = [];

  @Watch("workplaces", { immediate: true })
  protected syncPositions(): void {
    this.formWorkplacesValue = this.workplaces;
    this.updateGraphqlInput();
  }
  protected assignPositions(): void {
    this.workplaces = this.formWorkplacesValue.map((work) => ({
      ...work,
      pivot: { is_primary: false },
    }));
    this.formVisible = false;
    this.updateGraphqlInput();
  }

  protected assignPrimary(item: DoctorWorkplace): void {
    this.workplaces = [...this.workplaces].map((workplace) => ({
      ...workplace,
      pivot: { is_primary: workplace.id === item.id },
    }));
  }

  @Emit("updateGraphqlInput")
  protected updateGraphqlInput(): SyncInput {
    return {
      sync: this.formWorkplacesValue.map((pos) => pos.id),
    };
  }

  protected search: string = "";
  protected readonly hospitalTypes = hospitalTypes;
  protected createWorkplaceModel = {
    name: "",
    type: null,
  };
  protected createWorkplaceVisible = false;
  protected createWorkplaceProgress = false;
  protected async createWorkplace(): Promise<void> {
    try {
      this.createWorkplaceProgress = true;
      await this.$apollo.mutate({
        mutation: createDoctorWorkplace,
        variables: {
          name: this.createWorkplaceModel.name,
          hospital_type: this.createWorkplaceModel.type,
        },
      });
      await this.$apollo.queries.allDoctorsWorkplaces.refetch();
      this.createWorkplaceModel = {
        name: "",
        type: null,
      };
      this.createWorkplaceVisible = false;
    } catch (e) {
      console.error(e);
    } finally {
      this.createWorkplaceProgress = false;
    }
  }
}
