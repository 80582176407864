



















































































import { Component, Prop, Vue } from "vue-property-decorator";
import { UpdateUser } from "@/graphql/queries/User.graphql";
import PasswordInput from "@/components/widgets/commons/Inputs/PasswordInput.vue";
import * as rules from "@/core/validation";

@Component({
  components: {
    PasswordInput,
  },
})
export default class UserPassword extends Vue {
  @Prop({ type: String, default: "" }) userId!: string;
  protected readonly updateMutation = UpdateUser;
  protected readonly rules = rules;

  protected newPassword = "";
  protected newPasswordConfirm = "";
  protected done = false;
  protected visible = false;
  protected valid = false;
  protected showPassword = false;
}
