























































































import { Vue, Component, VModel, Watch, Prop } from "vue-property-decorator";
import { Role } from "@/generated/graphql";
import { Roles } from "@/graphql/queries/Roles.graphql";

@Component({
  name: "UserRoles",
  apollo: {
    roles: {
      query: Roles,
    },
  },
})
export default class extends Vue {
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @VModel({ type: Array, default: () => [] }) items!: Array<Role>;

  protected search: string = "";
  protected revokeRole: Role | boolean = false;
  protected visible: boolean = false;
  protected roles: Array<Role> = [];
  protected selected: Array<Role> = [];

  protected revoke(role: Role): void {
    this.items.splice(this.items.indexOf(role), 1);
  }

  protected assign(): void {
    this.items = [...this.selected];
    this.visible = false;
  }

  protected cancel(): void {
    this.selected = [...this.items];
    this.visible = false;
  }

  @Watch("items")
  private updateLocalItems(): void {
    this.selected = this.items;
  }
}
