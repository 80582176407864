import BaseTransformer from "@/core/Transformers/BaseTransformer";
import {
  DoctorWorkplace,
  SyncDoctorWorkplaces,
  SyncInput,
  UpdateDoctorProfileInput,
  UpdateDoctorWorkplacesInput,
} from "@/generated/graphql";

type BaseId = string | number;
type IDList = Array<BaseId>;
type Sync = { sync: SyncInput["sync"] };
type Connect = { connect: IDList };

export default class MutationTransformer<
  T extends Record<string, unknown> & { id: string }
> extends BaseTransformer {
  public transformDictToSync(
    dict: Record<string, Array<T>>
  ): Record<string, Sync> {
    return this.transformDictTo("sync", dict) as Record<string, Sync>;
  }

  public transformDictToConnect(
    dict: Record<string, Array<T>>
  ): Record<string, Connect> {
    return this.transformDictTo("connect", dict) as Record<string, Connect>;
  }
  public transformArrayToSync(values: Array<T>): Sync {
    return this.transformArrayUniquesToSyncField(values);
  }
  public transformArrayToConnect(values: Array<T>): Connect {
    return this.transformArrayUniquesToConnectField(values);
  }

  private mapIds(values: Array<T>): Array<T["id"]> {
    return Array.isArray(values) ? values.map((v) => v.id) : [];
  }

  private transformArrayUniquesToSyncField(values: Array<T>): Sync {
    return { sync: this.mapIds(values) };
  }
  private transformArrayUniquesToConnectField(values: Array<T>): Connect {
    return { connect: this.mapIds(values) };
  }

  private transformDictTo(
    type: "sync" | "connect",
    dict: Record<string, Array<T>>
  ): Record<string, Sync | Connect> {
    const normalized = this.normalize(dict);
    const transformedDict: Record<string, Sync | Connect> = {};
    const transformType =
      type === "sync"
        ? "transformArrayUniquesToSyncField"
        : "transformArrayUniquesToConnectField";
    for (const key in normalized)
      if (normalized[key]) {
        transformedDict[key] = this[transformType](normalized[key]);
      }

    return transformedDict;
  }

  public static transformDoctorWorkplaces(
    data: DoctorWorkplace[]
  ): UpdateDoctorWorkplacesInput {
    const sync = { sync: [] };
    const list: SyncDoctorWorkplaces[] = data.map((workplace) => ({
      id: workplace.id,
      is_primary: false,
    }));

    return sync;
  }
}
