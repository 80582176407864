





























































































































































































































































import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import PageCard from "@components/page/Card.vue";
import { Json } from "@/generated/graphql";
import { checkDuplicateContact } from "@/core/validation";
import PhoneInput from "@components/PhoneInput.vue";

@Component({
  name: "UserContacts",
  components: { PhoneInput, PageCard },
  methods: {
    checkDuplicateContact,
  },
})
export default class UserContacts extends Vue {
  @VModel({ type: Array, default: () => [] }) items!: Json[];
  @Prop({ type: Boolean, default: false }) loading!: boolean;
  @Prop({ type: Boolean, default: false }) namedContacts!: boolean;

  @Prop({ type: Array, default: () => [] })
  public readonly rules!: Array<(value: string) => boolean | string>;

  @Prop({ type: String, default: "" }) contactType!: string;

  protected editFormValid = false;
  protected createFormValid = false;

  protected creationFormVisible: boolean | null = false;
  protected ediPromptVisible: number | null = null;
  protected deleteConfirmVisible: boolean | null = null;
  protected editableItem: Json = {
    title: "",
    value: "",
    is_main: false,
  };

  protected resetEditableItem(): void {
    this.editableItem = {
      title: "",
      value: "",
      is_main: false,
    };
  }

  protected change(): void {
    if (null === this.ediPromptVisible) return;
    if (!this.items) this.items = [];
    this.items[this.ediPromptVisible] = this.editableItem;
    this.ediPromptVisible = null;
    this.editableItem = {};
  }

  protected saveContact(editableItem: any): void {
    if (!this.items?.length) {
      this.items = [{ ...this.editableItem }];
    } else {
      if (this.contactType === "phone") {
        this.editableItem.value =
          editableItem.value.replace(/[^\d]/g, "") || "";
        this.items.push({ ...this.editableItem });
      } else {
        this.items.push({ ...this.editableItem });
      }
    }
    this.creationFormVisible = false;
    this.ediPromptVisible = null;
    this.resetEditableItem();
  }

  protected get mainContact(): string {
    return this.items.find((contact) => contact.is_main)?.value || "";
  }

  protected set mainContact(value: string) {
    this.items = this.items.map((contact) => {
      const newContact = { ...contact };
      if (contact.value === value) newContact.is_main = true;
      else newContact.is_main = false;
      return newContact;
    });
  }
}
