





















import { Component, Vue, VModel, Prop } from "vue-property-decorator";
import * as rules from "@/core/validation";

@Component
export default class PasswordInput extends Vue {
  @Prop({ type: String, default: "Введите пароль" }) placeholder!: string;
  @Prop({ type: String, default: "Пароль" }) label!: string;
  @Prop({ type: Array, default: () => [] }) errorMessages!: string[];
  @Prop({
    type: Array,
    default: () => rules.passwordSet,
  })
  rules!: CallableFunction[];

  @VModel({ type: String, default: "" }) password!: string;

  protected showPassword = false;
}
