import ApolloClient from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { ApolloLink } from "apollo-link";
import { InMemoryCache } from "apollo-cache-inmemory";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import omitDeep from "omit-deep-lodash";
// @ts-ignore
import { createUploadLink } from "apollo-upload-client";
import Echo from "laravel-echo";
//@ts-ignore
import io from "socket.io-client";

//@ts-ignore
window.io = io;

const httpLink = createUploadLink({
  uri: process.env.VUE_APP_API_TARGET,
  credentials: "include",
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = RegExp("XSRF-TOKEN[^;]+").exec(document.cookie);
  const dtoken = decodeURIComponent(
    token ? token.toString().replace(/^[^=]+./, "") : ""
  );
  operation.setContext({
    headers: { "X-XSRF-TOKEN": dtoken },
  });
  return forward(operation);
});

const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = omitDeep(operation.variables, "__typename");
  }
  return forward(operation).map((data) => {
    return data;
  });
});

const token = RegExp("XSRF-TOKEN[^;]+").exec(document.cookie);
const dtoken = decodeURIComponent(
  token ? token.toString().replace(/^[^=]+./, "") : ""
);

const echoClient = new Echo({
  broadcaster: "socket.io",
  host: process.env.VUE_APP_WEBSOCKETS,
  auth: {
    headers: {
      "X-XSRF-TOKEN": dtoken,
      Origin: location.origin,
    },
  },
});

const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    HttpLink.from([cleanTypeName, authMiddleware, httpLink]),
  ]),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache",
    },
  },
});

export { apolloClient, echoClient };
