var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{attrs:{"color":"red"},model:{value:(_vm.done),callback:function ($$v) {_vm.done=$$v},expression:"done"}},[_vm._v(" Пользователь удален ")]),_c('ApolloMutation',{attrs:{"mutation":_vm.deleteMutation,"variables":{ id: _vm.userId }},on:{"done":function($event){_vm.visible = false;
      _vm.$emit('deleted');}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var mutate = ref.mutate;
      var loading = ref.loading;
      var error = ref.error;
      var gqlError = ref.gqlError;
return [_c('v-dialog',{attrs:{"persistent":loading,"max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"red","text":""}},on),[_vm._v("удалить аккаунт")])]}}],null,true),model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-card',{attrs:{"loading":loading,"disabled":loading}},[_c('v-card-title',[_vm._v("УДАЛИТЬ АККАУНТ?")]),_c('v-expand-transition',[(error)?_c('v-alert',{attrs:{"color":"red","text":"","dismissible":"","dense":""}},[_c('span',{staticClass:"text-body-2"},[_vm._v(" Произошла ошибка, удалить пользователя не получилось ")]),_c('details',[_vm._v(" "+_vm._s(error || gqlError)+" ")])]):_vm._e()],1),_c('v-card-text',[_vm._v(" Это действие нельзя отменить, ПОЛЬЗОВАТЕЛЬ будет УДАЛЕН! ")]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"red","text":"","loading":loading},on:{"click":mutate}},[_vm._v(" Удалить пользователя ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.visible = false}}},[_vm._v("отменить")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }