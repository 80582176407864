import Vue from "vue";
import Vuex from "vuex";
import employee from "./employee";
import users from "./users";
import session from "./session";
import {
  Actions,
  createStore,
  Getters,
  Module,
  Mutations,
} from "vuex-smart-module";
import { User } from "@/generated/graphql";

Vue.use(Vuex);

class RootState {}
class RootMutations extends Mutations<RootState> {}

// Using RootGetters as utils store
class RootGetters extends Getters<RootState> {
  /**
   * trim long username string with ending dots
   * @param username
   * @param options
   */
  public trimUserFullName(
    username: string,
    options: { maxLength: number } = { maxLength: 25 }
  ) {
    return username.length >= options.maxLength
      ? [username.substring(0, options.maxLength), "..."].join("")
      : username;
  }

  /**
   * Merge user first and family names into a string
   * @param user
   * @param options
   */
  public parseUserFullName(
    user: User,
    options: { trimUsername: boolean } = { trimUsername: true }
  ) {
    const { first_name, family_name } = user;
    const fullNameString = [first_name, family_name].join(" ");

    return options?.trimUsername
      ? this.getters.trimUserFullName(fullNameString)
      : fullNameString;
  }
}

class RootActions extends Actions<
  RootState,
  RootGetters,
  RootMutations,
  RootActions
> {}

export const root = new Module({
  state: RootState,
  mutations: RootMutations,
  actions: RootActions,
  getters: RootGetters,
  modules: {
    employee,
    users,
    session,
  },
});

export const store = createStore(root);
