import { Getters, Mutations, Actions, Module } from "vuex-smart-module";
import { Employee, Employees } from "@/types/employees";
import { Headers } from "@/types/table";
import { EventRecord } from "@/types/event";
import { UserQuery } from "@/types/user";
import { GqlEmployeesQueryQuery } from "@/generated/graphql";
import GQLEmployeesQuery from "@/graphql/queries/Employees.graphql";
import { Commit } from "vuex";
import { apolloClient } from "@/core/apollo";
class EmployeeState {
  headers: Headers = [
    { text: "ID", value: "id" },
    { text: "ФИО", value: "fio" },
    { text: "Должность", value: "profession" },
    { text: "E-mail", value: "email" },
    { text: "Телефон", value: "phone" },
    { text: "Печать", value: "print", sortable: false },
    { text: "", value: "_actions", sortable: false },
  ];
  visibleHeaders: Headers = [...this.headers];
  employees: Employees = [];
  settings: Array<number> = [];

  events: EventRecord[] = [
    {
      id: 1,
      name: "lorem ipsum",
      leading: "Fiona Loud",
      school: "Awesome School",
      city: "St. Francisco",
      date: new Date().toLocaleDateString(),
      programType: "awesome type",
      scores: 23,
      scoresCode: "J785GH",
      status: "status",
    },
    {
      id: 2,
      name: "lorem ipsum 2",
      leading: "Sara Connor",
      school: "Awesome School",
      city: "Los Angeles",
      date: new Date().toLocaleDateString(),
      programType: "awesome type",
      scores: 23,
      scoresCode: "J785GH",
      status: "status",
    },
  ];
}

class EmployeeGetters extends Getters<EmployeeState> {
  get visibleHeaders(): Headers {
    return this.state.visibleHeaders;
  }
  get employees(): Employees {
    return this.state.employees;
  }
  get headers(): Headers {
    return this.state.headers;
  }
  get settings(): Array<number> {
    return this.state.settings;
  }
  get events(): EventRecord[] {
    return this.state.events;
  }
}

class EmployeeMutations extends Mutations<EmployeeState> {
  setVisibleHeaders(settings: Array<number>): void {
    this.state.visibleHeaders = this.state.headers.filter(
      (_, index: number) => !settings.includes(index)
    );
    this.state.settings = [...settings];
  }
  setSettings(settings: Array<number>): void {
    this.state.settings = [...settings];
  }
  setEmployees(employees: Employees): void {
    this.state.employees = [...employees];
  }
}

class EmployeeActions extends Actions<
  EmployeeState,
  EmployeeGetters,
  EmployeeMutations,
  EmployeeActions
> {
  async getEmployees({ page, first }: UserQuery) {
    const commit: Commit = this.commit;
    try {
      const data = await apolloClient.query({
        query: GQLEmployeesQuery,
        variables: {
          page,
          first,
        },
      });
      const users: GqlEmployeesQueryQuery[] = [...data?.data?.employees?.data];
      const total: number = data?.data?.employees?.paginatorInfo.total;
      if (!users) return [];
      const callback = (
        previousValue: string,
        currentValue: { name: string }
      ) => {
        previousValue += currentValue.name + " ";
        return previousValue;
      };
      const callbackContacts = (
        previousValue: string,
        currentValue: { title: string; value: string }
      ) => {
        previousValue += currentValue.title + ": " + currentValue.value + " ";
        return previousValue;
      };
      const mappedUser: Employee[] = users.map((user: any) => {
        const obj: any = {};
        obj[
          "fio"
        ] = `${user["family_name"]} ${user["first_name"]} ${user["second_name"]}`;
        obj["id"] = user["id"];

        obj["email"] = user["emails"]?.reduce(callbackContacts, "");
        obj["phone"] = user["phones"]?.reduce(callbackContacts, "");
        obj["specialisation"] = user["employeeProfile"]?.specialties?.reduce(
          callback,
          ""
        );
        obj["association"] = user["employeeProfile"]?.positions?.reduce(
          callback,
          ""
        );
        obj["city"] = user["city"];
        return obj;
      });
      commit("setEmployees", mappedUser);
      return { employees: mappedUser, total };
    } catch (e) {
      console.error(e);
    }
  }
}

export default new Module({
  state: EmployeeState,
  mutations: EmployeeMutations,
  getters: EmployeeGetters,
  actions: EmployeeActions,
});
