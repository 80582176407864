























import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
  name: "DownloadButton",
})
export default class DownloadButton extends Vue {
  @Prop(String) source!: string;

  isLoading: boolean = false;
  done: boolean = false;

  async download(): Promise<void> {
    this.isLoading = true;
    const response: Response = await fetch(this.source);
    const blob: Blob = await response.blob();

    this.isLoading = false;
    this.done = true;

    const objectUrl = URL.createObjectURL(blob);
    const link: HTMLAnchorElement = document.createElement("a");
    link.href = objectUrl;
    link.download = this.source.split("/").pop() as string;
    link.click();
  }
}
