var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',{attrs:{"loading":_vm.$apollo.loading}},[_c('v-card-title',[_vm._v("Начисления баллов НМО")]),_c('v-card-text',[_c('v-btn',{attrs:{"small":"","outlined":"","color":"primary","loading":_vm.$apollo.queries.eventsWithNmo.loading},on:{"click":function($event){_vm.visibleAssignForm = true}}},[_vm._v(" Добавить ")]),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.visibleAssignForm),callback:function ($$v) {_vm.visibleAssignForm=$$v},expression:"visibleAssignForm"}},[_c('card',[_c('v-card-title',[_vm._v("Начисление балла НМО пользователю")]),_c('v-data-table',{attrs:{"loading":_vm.$apollo.queries.eventsWithNmo.loading,"headers":[
            {
              text: 'Событие',
              value: 'public_name',
            },
            {
              text: 'Баллы',
              value: 'amount_nmo',
            },
            { value: 'assign' } ],"items":_vm.eventsWithAvailableNmo},scopedSlots:_vm._u([{key:"item.assign",fn:function(ref){
          var item = ref.item;
return [_c('ApolloMutation',{attrs:{"mutation":_vm.AssignNMO,"variables":{ user_id: _vm.userId, event_id: item.id }},on:{"done":_vm.refetchData},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var mutate = ref.mutate;
          var loading = ref.loading;
return [_c('v-btn',{attrs:{"small":"","text":"","color":"primary","loading":loading},on:{"click":mutate}},[_vm._v("Назначить")])]}}],null,true)})]}},{key:"no-data",fn:function(){return [_vm._v(" Нет доступных баллов для этого пользователя ")]},proxy:true}])}),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.visibleAssignForm = false}}},[_vm._v("отмена")])],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.nmoPoints.data,"items-per-page":_vm.pagination.first,"page":_vm.pagination.page,"server-items-length":_vm.nmoPoints.paginatorInfo.total},on:{"update:page":_vm.changePage,"update:items-per-page":_vm.changePerPage},scopedSlots:_vm._u([{key:"item.event",fn:function(ref){
          var item = ref.item;
return [_c('a',{attrs:{"target":"_blank","href":("/event/" + (item.event.id))}},[_vm._v(_vm._s(item.event.public_name))])]}},{key:"item.date",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.event.date_from))+" ")]}},{key:"item._actions",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"ml-n4"},[_c('ApolloMutation',{attrs:{"mutation":_vm.RemoveNmo,"variables":{ user_id: item.user.id, event_id: item.event.id }},on:{"done":_vm.refetchData},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var loading = ref.loading;
          var mutate = ref.mutate;
          var error = ref.error;
return [_c('v-btn',{attrs:{"color":"red","text":"","small":""},on:{"click":function($event){_vm.revokeId = item.id}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-undo-variant")]),_vm._v(" отозвать баллы ")],1),_c('v-dialog',{attrs:{"value":_vm.revokeId === item.id,"persistent":loading,"max-width":"400"}},[_c('v-card',{staticClass:"rounded-md",attrs:{"loading":loading,"disabled":loading}},[_c('v-card-title',[_vm._v("Отозвать баллы НМО?")]),_c('v-card-text',[_c('v-expand-transition',[(error)?_c('v-alert',{attrs:{"color":"red","dense":"","text":""}},[_vm._v(" ошибка "),_c('details',[_vm._v(" "+_vm._s(error)+" ")])]):_vm._e()],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"red","text":"","loading":loading},on:{"click":mutate}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-undo-variant")]),_vm._v(" отозвать баллы ")],1),_c('v-btn',{staticClass:"ml-2",attrs:{"text":""},on:{"click":function($event){_vm.revokeId = null}}},[_vm._v(" отменить")])],1)],1)],1)]}}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }