





















































import { Component } from "vue-property-decorator";
import { BaseView } from "@/core/UI/Views/BaseView";
import { Routes } from "@/types/core";
import { getBuildInfo } from "vue-cli-plugin-build-info/plugin";

@Component({
  name: "App",
  components: {
    Header: () => import("@components/parts/Header.vue"),
    Sidebar: () => import("@components/parts/Sidebar.vue"),
    LoadingScreen: () => import("@components/parts/LoadingScreen.vue"),
  },
})
export default class App extends BaseView {
  public get buildInfo() {
    const info = getBuildInfo();
    const intl = new Intl.DateTimeFormat("ru-RU", {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    return {
      version: info.VERSION,
      hash: info.COMMIT,
      date: intl.format(new Date(info.TIMESTAMP)),
    };
  }
  async redirectToLogin(): Promise<void> {
    await this.$router.replace({
      name: Routes.login,
      query: { return: this.$route.fullPath },
    });
  }
}
