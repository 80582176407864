



































































import {
  Vue,
  Component,
  Prop,
  VModel,
  PropSync,
  Watch,
} from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import Settings from "./Settings.vue";
import Export from "@components/parts/tables/Export.vue";
import PageCard from "@components/page/Card.vue";
import { TableHeader } from "@/components/parts/tables/Settings.vue";

@Component({
  components: { Settings, PageCard, Export },
})
export default class Table extends Vue {
  @Prop({ type: String, default: "" }) label!: string;
  @Prop({ type: Boolean, default: false }) disableExport!: boolean;
  @Prop({ type: Boolean, default: false }) loading!: boolean;
  @Prop({ type: Boolean, default: false }) showSelect!: boolean;
  @Prop({ type: Array, default: () => [] }) headers!: DataTableHeader[];
  @Prop({ type: Array, default: () => [] }) items!: Event[];
  @Prop({ type: Object, default: () => ({}) }) exportResolvers!: Record<
    string,
    CallableFunction
  >;

  @PropSync("visibleColumns", { type: Array, default: () => [] })
  visibleColumnsSync!: TableHeader[];

  @PropSync("searchValue", { type: String, default: "" }) searchText!: string;

  @PropSync("filterValues", { type: Object, default: () => ({}) })
  columnFilter!: Record<string, string>;

  @VModel({ type: Array, default: () => [] })
  public selected!: unknown[];

  get allowExport() {
    if (this.disableExport) return false;

    return this.$store.getters["session/isAllow"](
      "CREATE_EVENT_PARTICIPANTS_REPORT"
    );
  }

  /**
   * Метод доступен через $ref.[table ref name].visibleHeaders
   */
  public visibleHeaders: TableHeader[] = [];

  @Watch("visibleHeaders", { immediate: true })
  private shareVisibleHeaders(headers: TableHeader[]): void {
    this.visibleColumnsSync = headers;
  }
}
