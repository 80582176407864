























import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "PageSection",
})
export default class PageSection extends Vue {
  @Prop(String) subheader!: string;
  @Prop({ type: String, default: "grey lighten-3" }) color!: boolean;
  protected visible = true;
}
