const requiredENV = [
  "VUE_APP_API_CSRF_COOKIE_TARGET",
  "VUE_APP_API_TARGET",
  "VUE_APP_API_PROXY",
  "VUE_APP_GRAPHQL_SCHEMA",
  "VUE_APP_DADATA_SUGGESTIONS",
  "VUE_APP_DADATA_TOKEN",
  "VUE_APP_WEBSOCKETS",
  "VUE_APP_SECRET_MAPS_KEY",
];

export const verify = (): void =>
  requiredENV.forEach((envVar) => {
    if (!process.env[envVar])
      console.error(
        `[ APP:ENV ]: Не установлена переменная окружения ${envVar}`
      );
  });
