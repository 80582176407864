var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-lazy',[_c('ApolloQuery',{attrs:{"notify-on-network-status-change":"","query":_vm.UserDocuments,"variables":{ id: _vm.userId }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var data = ref_result.data;
var loading = ref_result.loading;
var error = ref_result.error;
var query = ref.query;
return [_c('card',{attrs:{"loading":loading,"disabled":loading}},[(error)?_c('div',{staticClass:"pa-4"},[(error)?_c('v-alert',{attrs:{"color":"red","text":""}},[_vm._v(" Ошибка загрузки документов "),_c('details',[_vm._v(" "+_vm._s(error)+" ")])]):_vm._e()],1):_c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.mapDocs(data)},scopedSlots:_vm._u([{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('Download',{attrs:{"source":item.url}})]}},{key:"item.application",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.application ? item.application.title : "-"))]}}],null,true)}),_c('v-card-actions',[_c('ApolloMutation',{attrs:{"mutation":_vm.UploadMutation},on:{"done":function($event){_vm.uploadFormVisible = false;
              _vm.$refs.form.reset();
              query.refetch();}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var mutate = ref.mutate;
              var uploading = ref.loading;
              var error = ref.error;
              var gqlError = ref.gqlError;
return [_c('v-dialog',{attrs:{"max-width":"500","persistent":uploading},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","text":"","color":"primary","loading":uploading}},on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-upload")]),_vm._v(" Загрузить документ ")],1)]}}],null,true),model:{value:(_vm.uploadFormVisible),callback:function ($$v) {_vm.uploadFormVisible=$$v},expression:"uploadFormVisible"}},[_c('card',{attrs:{"loading":uploading,"disabled":uploading}},[_c('v-form',{ref:"form",model:{value:(_vm.isValidForm),callback:function ($$v) {_vm.isValidForm=$$v},expression:"isValidForm"}},[_c('v-card-title',[_vm._v("Загрузка документа")]),(error || gqlError)?_c('v-alert',{attrs:{"color":"red","text":""}},[_vm._v(" Произошла ошибка "),_c('details',[_vm._v(" "+_vm._s(error || gqlError)+" ")])]):_vm._e(),_c('v-card-text',[_c('v-file-input',{attrs:{"rules":[
                        function (v) { return !!v || 'Файл обязателен'; },
                        function (v) { return !v ||
                          v.size < 10e6 ||
                          'Размер файла должен быть меньше 10МБ'; } ],"chips":"","show-size":"","label":"Файл документа"},model:{value:(_vm.form.file),callback:function ($$v) {_vm.$set(_vm.form, "file", $$v)},expression:"form.file"}}),_c('v-select',{attrs:{"items":data && data.user ? data.user.applications : [],"item-text":"title","item-value":"id","label":"Выбрать заявку"},model:{value:(_vm.form.application_id),callback:function ($$v) {_vm.$set(_vm.form, "application_id", $$v)},expression:"form.application_id"}}),_c('v-textarea',{attrs:{"label":"Комментарий"},model:{value:(_vm.form.comment),callback:function ($$v) {_vm.$set(_vm.form, "comment", $$v)},expression:"form.comment"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","text":"","disabled":!_vm.isValidForm,"loading":uploading},on:{"click":function($event){return mutate({ variables: _vm.uploadVariables })}}},[_vm._v(" Загрузить документ ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.uploadFormVisible = false}}},[_vm._v(" отмена ")])],1)],1)],1)],1)]}}],null,true)})],1)],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }