


















































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { Application } from "@/generated/graphql";
import { DataTableHeader } from "vuetify";
import { ApplicationStatus, ApplicationType } from "@/core/static/dict";
import { DateTime } from "luxon";

@Component
export default class UserApplicationsTable extends Vue {
  @Prop({ type: [Array], default: () => [] }) items!: Application[];
  @Prop({ type: Boolean, default: false }) readonly!: boolean;

  protected moreViewId: string | null = "";
  protected readonly applicationStatus = ApplicationStatus;
  protected readonly applicationType = ApplicationType;

  protected get headers(): DataTableHeader[] {
    return [
      { text: "id", value: "id" },
      { text: "Дата и время", value: "created_at" },
      { text: "Тип заявки", value: "type" },
      { text: "Инициатор", value: "name" },
      { text: "Карточка обращения", value: "more" },
      { text: "Статус", value: "status" },
      { text: "", value: "actions", sortable: false },
    ];
  }

  protected getUserName(item: Application): string {
    return item.user
      ? `${item.user.family_name} ${item.user.first_name}`
      : item.name ?? "не указан";
  }

  protected formatDate(date: string): string {
    return DateTime.fromISO(date).setLocale("RU").toFormat("DD, hh:mm");
  }
}
