























import { Vue, Component, VModel, Prop } from "vue-property-decorator";
import { Debounce } from "vue-debounce-decorator";
import { CityInput as City } from "@/generated/graphql";
import { isNonNullable } from "@/core/typeGuards";
import { Maybe } from "graphql/jsutils/Maybe";

type DadataResponse = {
  value?: Maybe<string>;
  data?: {
    geoname_id?: Maybe<string>;
    city_fias_id?: Maybe<string>;
    city?: Maybe<string>;
    region_fias_id?: Maybe<string>;
    region_with_type?: Maybe<string>;
    country?: Maybe<string>;
    fias_id?: Maybe<string>;
  };
};

@Component
export default class CityAutocomplete extends Vue {
  @Prop({ type: Array, default: () => [] }) errorMessages!: string[];

  @VModel({ type: [Object, Array, Number, String], default: () => undefined })
  city?: City;

  @Prop({ type: Array, default: () => [] })
  rules!: Array<(v: string) => true | string>;

  searchInput: string | null = "";
  loading: boolean = false;
  suggestions: City[] = [];

  private onBlur() {
    this.searchInput = this.city && this.city.name ? this.city.name : null;
  }

  private dadataToCityInput(value: DadataResponse): City | undefined {
    const data = value.data;
    if (data) {
      const dadata_code = data.city_fias_id || data.geoname_id;
      const name = value.value;
      if (value.data) {
        const name_short = value.data.city;

        const country = {
          dadata_code: data.fias_id || data.geoname_id,
          name: value.data.country,
          name_short: value.data.country,
        };

        if (!isNonNullable(country)) {
          return undefined;
        }

        const region = {
          dadata_code: data.region_fias_id || data.geoname_id,
          name: value.data.region_with_type,
          name_short: value.data.region_with_type,
          country,
        };

        if (!isNonNullable(region)) {
          return undefined;
        }

        const city = {
          dadata_code,
          name,
          name_short,
          region,
        };

        if (isNonNullable(city)) {
          return city;
        }
      }
    }
    return undefined;
  }

  protected get cityName(): string {
    return this.city ? (this.city.name as string) : "";
  }

  @Debounce(200)
  async getSuggestions(query: string): Promise<void> {
    const url: string = process.env.VUE_APP_DADATA_SUGGESTIONS as string;
    const token: string = process.env.VUE_APP_DADATA_TOKEN as string;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({
        query,
        from_bound: { value: "city" },
        to_bound: { value: "settlement" },
        locations: [{ country: "*" }],
      }),
    };

    try {
      this.loading = true;
      const { suggestions } = await (await fetch(url, options)).json();
      if (suggestions instanceof Array) {
        this.suggestions = (suggestions as DadataResponse[]).reduce(
          (previousValue: City[], currentValue) => {
            const city = this.dadataToCityInput(currentValue);
            if (city) {
              previousValue.push(city);
            }
            return previousValue;
          },
          []
        );
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }
}
