var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({attrs:{"disable-sort":"","headers":_vm.headers,"items":_vm.items},on:{"click:row":function($event){!_vm.readonly && _vm.$emit('click:row', $event)}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.applicationStatus[item.status])+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.applicationType[item.type])+" ")]}},{key:"item.answer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.answer)+" ")]}},{key:"item.more",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"value":_vm.moreViewId === item.id,"persistent":"","scrollable":"","width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","x-small":"","color":"primary"},on:{"click":function($event){_vm.moreViewId = item.id}}},'v-btn',attrs,false),on),[_vm._v(" Смотреть карточку обращения ")])]}}],null,true)},[_c('v-card',[_c('v-card-title',[_vm._v("Просмотр заявки")]),_c('v-card-text',[_c('div',{staticClass:"grey lighten-3 mb-10 mx-n6 px-6"},[_c('v-row',[_c('v-col',[_c('v-subheader',[_vm._v("Статус")]),_c('v-chip',{attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.applicationStatus[item.status]))])],1),_c('v-col',[_c('v-subheader',[_vm._v("Тип заявки")]),_c('v-chip',{attrs:{"small":"","color":"purple","dark":""}},[_vm._v(" "+_vm._s(_vm.applicationType[item.type]))])],1)],1)],1),_c('h2',{staticClass:"text-h4 black--text mb-2"},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(item.user ? ((item.user.family_name) + " " + (item.user.first_name)) : "не указан")+" ")]),_c('v-timeline',{attrs:{"dense":""}},[_c('v-timeline-item',{attrs:{"small":""}},[(item.message)?_c('v-card',{staticClass:"mt-6 elevation-2 pa-4",domProps:{"textContent":_vm._s(item.message)}}):_c('span',[_vm._v(" нет текста ")])],1),_c('v-timeline-item',{attrs:{"small":""}},[(item.answer)?_c('v-card',{staticClass:"elevation-2 pa-4",attrs:{"color":"primary","dark":""},domProps:{"textContent":_vm._s(item.answer)}}):_c('span',[_vm._v(" пока нет ответа ")])],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.moreViewId = null}}},[_vm._v("Закрыть")])],1)],1)],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getUserName(item))+" ")]}},(_vm.$scopedSlots.actions)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_vm._t("actions",null,null,{ item: item })]}}:null],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }