
















































import { Component, Prop, Vue } from "vue-property-decorator";
import { deleteUser } from "@/graphql/queries/User.graphql";

@Component
export default class UserDeleteButton extends Vue {
  @Prop({ type: String, default: "" }) userId!: string;
  protected readonly deleteMutation = deleteUser;

  protected done = false;
  protected visible = false;
}
