









































































































import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";
import {
  createDoctorPosition,
  AllDoctorPositions,
} from "@/graphql/queries/DoctorsPositions.graphql";
import {
  CreateDoctorPositionMutation,
  CreateDoctorPositionMutationVariables,
  DoctorPosition,
  EmployeePosition,
} from "@/generated/graphql";
import {
  AllEmployeesPositions,
  createEmployeePosition,
} from "@/graphql/queries/EmployeesPositions.graphql";
import * as rules from "@/core/validation";

@Component({
  apollo: {
    allDoctorsPositions: {
      query: AllDoctorPositions,
      skip: true,
      fetchPolicy: "no-cache",
    },
    allEmployeesPositions: {
      query: AllEmployeesPositions,
      skip: true,
      fetchPolicy: "no-cache",
    },
  },
})
export default class Positions extends Vue {
  @VModel({ type: Array, default: () => [] }) items!: Array<
    DoctorPosition | EmployeePosition
  >;
  @Prop({ type: Boolean, default: false }) loading!: boolean;
  @Prop({ type: Boolean, default: false }) isEmployee!: boolean;

  readonly rules = rules;

  protected formVisible = false;
  protected allDoctorsPositions: DoctorPosition[] = [];
  protected allEmployeesPositions: EmployeePosition[] = [];

  protected loadPositions(): void {
    this.isEmployee
      ? this.$apollo.queries.allEmployeesPositions.start()
      : this.$apollo.queries.allDoctorsPositions.start();
  }
  protected get positions(): Array<DoctorPosition | EmployeePosition> {
    return this.isEmployee
      ? this.allEmployeesPositions
      : this.allDoctorsPositions;
  }
  protected get positionsLoading(): boolean {
    return this.isEmployee
      ? this.$apollo.queries.allEmployeesPositions.loading
      : this.$apollo.queries.allDoctorsPositions.loading;
  }

  protected formPositionsValue: Array<DoctorPosition | EmployeePosition> = [];

  private mounted() {
    this.loadPositions();
  }

  @Watch("items", { immediate: true })
  protected syncPositions(): void {
    this.formPositionsValue = this.items;
  }
  protected assignPositions(): void {
    this.items = this.formPositionsValue;
    this.formVisible = false;
  }

  protected createPositionProgress = false;
  protected search: string = "";
  protected async createPosition(): Promise<void> {
    try {
      this.createPositionProgress = true;
      await this.$apollo.mutate<
        CreateDoctorPositionMutation,
        CreateDoctorPositionMutationVariables
      >({
        mutation: this.isEmployee
          ? createEmployeePosition
          : createDoctorPosition,
        variables: { name: this.search },
      });

      if (this.isEmployee) {
        await this.$apollo.queries.allEmployeesPositions.refetch();
      } else {
        await this.$apollo.queries.allDoctorsPositions.refetch();
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.createPositionProgress = false;
    }
  }
}
