<template>
  <v-text-field
    v-model="phoneNumber"
    v-phone-mask="dynamicMask"
    type="tel"
    :placeholder="placeholder"
    :required="required"
    :rules="rules"
    :clearable="clearable"
    :label="label"
    :validate-on-blur="validateOnBlur"
    :dense="dense"
    :filled="filled"
    :persistent-placeholder="persistentPlaceholder"
    :append-outer-icon="appendOuterIcon"
    :background-color="backgroundColor"
    @click:append-outer="$emit('click:append-outer')"
  />
</template>

<script>
import { VueMaskDirective } from "v-mask";

const BASE_NUMBER_MASK = "################";

export default {
  directives: { "phone-mask": VueMaskDirective },

  props: {
    value: { type: [Number, String], default: "" },
    required: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    label: { type: String, default: "" },
    backgroundColor: { type: String, default: "" },
    appendOuterIcon: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    validateOnBlur: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    filled: { type: Boolean, default: false },
    persistentPlaceholder: { type: Boolean, default: false },
    rules: { type: Array, default: () => [] },
  },

  data() {
    return {
      dynamicMask: BASE_NUMBER_MASK,
    };
  },

  computed: {
    phoneNumber: {
      get() {
        return this.value;
      },
      set(number) {
        this.setPhoneMask(number);
      },
    },
  },

  methods: {
    setPhoneMask(number) {
      this.dynamicMask = BASE_NUMBER_MASK;

      // Если стерли значение
      if (!number) {
        this.dynamicMask = null;
        this.$emit("input", "");
        return;
      }

      if (number.charAt(0) === "8") {
        this.dynamicMask = "8 (###)-###-##-##";
        this.$emit("input", number);
        return;
      }

      if (
        number.charAt(0) === "7" ||
        number.slice(0, 2) === "+7" ||
        number.slice(0, 3) === "+ 7"
      ) {
        this.dynamicMask = "+ # (###)-###-##-##";
        this.$emit("input", number);
        return;
      }

      if (number.charAt(0) === "+") {
        this.dynamicMask = `+${BASE_NUMBER_MASK}`;
        this.$emit("input", number);
        return;
      }

      // Если любое другое число
      if (!isNaN(number)) {
        this.dynamicMask = `+${BASE_NUMBER_MASK}`;
        this.$emit("input", number);
        return;
      }

      this.$emit("input", number);
    },
  },
};
</script>
