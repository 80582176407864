































































































































import { Component, Vue } from "vue-property-decorator";
import {
  EventsWithNmoQuery,
  EventsWithNmoQueryVariables,
  NmoPointsQuery,
  NmoPointsQueryVariables,
} from "@/generated/graphql";
import { DataTableHeader } from "vuetify";
import { EventsWithNMO } from "@/graphql/queries/User.graphql";
import { nmoPoints } from "@/graphql/queries/NmoPoints.graphql";
import {
  assignNmoPoints,
  removeNmoPoints,
} from "@/graphql/queries/NmoPoints.graphql";
import Table from "@components/parts/tables/Table.vue";
import Card from "@components/page/Card.vue";

const initialPagination: {
  page: number;
  first: number;
} = {
  page: 1,
  first: 5,
};

@Component({
  components: {
    Table,
    Card,
  },
  apollo: {
    nmoPoints: {
      query: nmoPoints,
      variables(this: UserScores): NmoPointsQueryVariables {
        return {
          userId: this.userId,
          first: this.nmoPoints.paginatorInfo.perPage,
          page: this.nmoPoints.paginatorInfo.currentPage,
        };
      },
      update(data: NmoPointsQuery) {
        return data.nmoPoints;
      },
    },
    eventsWithNmo: {
      query: EventsWithNMO,
      variables(this: UserScores): EventsWithNmoQueryVariables {
        return {
          user_id: this.userId,
        };
      },
      update({ events_with_nmo }: EventsWithNmoQuery) {
        return events_with_nmo;
      },
      skip(this: UserScores) {
        return !this.visibleAssignForm;
      },
    },
  },
})
export default class UserScores extends Vue {
  protected readonly RemoveNmo = removeNmoPoints;
  protected readonly AssignNMO = assignNmoPoints;

  protected readonly headers: Array<DataTableHeader> = [
    { text: "id", value: "id", sortable: false },
    { text: "Мероприятие", value: "event", sortable: false },
    { text: "Дата", value: "date", sortable: false },
    { text: "Код", value: "code", sortable: false },
    { text: "Баллы", value: "points", sortable: false },
    { text: "Действия", value: "_actions", width: 200, sortable: false },
  ];

  pagination = { ...initialPagination };

  protected nmoPoints: NonNullable<NmoPointsQuery["nmoPoints"]> = {
    paginatorInfo: {
      perPage: 5,
      currentPage: 1,
      total: 0,
      count: 0,
      hasMorePages: true,
    },
    data: [],
  };

  eventsWithNmo: EventsWithNmoQuery["events_with_nmo"] = [];

  get eventsWithAvailableNmo(): EventsWithNmoQuery["events_with_nmo"] {
    return this.eventsWithNmo.filter(({ id }) => {
      return !this.nmoPoints.data.find(({ event }) => event.id === id);
    });
  }

  protected revokeId = null;
  protected visibleAssignForm = false;

  public get userId(): string {
    return this.$route.params.id;
  }

  async changePage(page: number) {
    this.pagination.page = page;

    // TODO: возможно тут стоит использовать пагинацию с fetchMore
    await this.$apollo.queries.nmoPoints.refetch({
      page: this.pagination.page,
      first: this.pagination.first,
      userId: this.userId,
    });
  }

  async changePerPage(perPage: number) {
    this.pagination.first = perPage;
    this.pagination.page = initialPagination.page;

    await this.$apollo.queries.nmoPoints.refetch({
      page: this.pagination.page,
      first: this.pagination.first,
      userId: this.userId,
    });
  }

  public async refetchData(): Promise<void> {
    await this.$apollo.queries.nmoPoints.refresh();
    this.revokeId = null;
    this.visibleAssignForm = false;
  }
}
