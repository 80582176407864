import { Component } from "vue-property-decorator";
import { BaseView } from "@/core/UI/Views/BaseView";
import { DocumentNode } from "graphql";
import { PaginatorInfo } from "@/generated/graphql";
import { Maybe } from "graphql/jsutils/Maybe";

@Component({
  apollo: {
    list: {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      query() {
        return this.fetchListQuery;
      },
      variables() {
        return { ...this.fetchListVariables, page: 1, first: 5 };
      },
      update(result) {
        return result?.[this.fetchKey];
      },
    },
  },
})
export default class ListView<T = unknown> extends BaseView {
  protected readonly fetchListQuery!: DocumentNode;
  protected readonly fetchKey!: string;
  protected readonly isArchive!: boolean;

  public list: Maybe<{
    data: Maybe<Array<T>>;
    paginatorInfo: Maybe<PaginatorInfo>;
  }> = {
    paginatorInfo: {
      count: 0,
      total: 0,
      currentPage: 1,
      perPage: 5,
      lastPage: 0,
      firstItem: 5,
      hasMorePages: true,
    },
    data: [],
  };

  protected get fetchListVariables(): Record<string, any> {
    return {};
  }

  public async refetch(): Promise<void> {
    await this.$apollo.queries.list.refetch({
      ...JSON.parse(JSON.stringify(this.fetchListVariables)),
      page: 1,
      first: this.list?.paginatorInfo?.perPage,
    });
  }

  public async refresh(): Promise<void> {
    await this.$apollo.queries.list.refresh();
  }

  public get tableBindings(): Record<string, unknown> {
    const paginatorInfo = this.list?.paginatorInfo;
    return {
      loading: this.$apollo.queries.list.loading,
      options: {
        itemsPerPage: paginatorInfo?.perPage,
        page: paginatorInfo?.currentPage,
      },
      footerProps: {
        itemsPerPageOptions: [5, 10, 15, 30, 50],
      },
      page: paginatorInfo?.currentPage,
      serverItemsLength: paginatorInfo?.total,
      first: paginatorInfo?.firstItem,
    };
  }

  protected async paginate({
    page,
    itemsPerPage,
  }: {
    page: number;
    itemsPerPage: number;
  }): Promise<void> {
    await this.$apollo.queries.list.refetch({
      ...this.fetchListVariables,
      page,
      first:
        itemsPerPage === -1 ? this.list?.paginatorInfo?.total : itemsPerPage,
    });
  }
  private getLocalVars(): any {
    //pass
  }

  onSearch(text: string): void {
    //pass
  }
}
