export interface IAsyncPropAction {
  (args?: unknown): Promise<void>;
}

export enum Routes {
  user = "User",
  userID = "UserID",
  users = "Users",
  employees = "Employees",
  employee = "Employee",
  employeeID = "employeeID",
  login = "Login",
  reset = "Reset",
  roles = "Roles",
  index = "Index",
  reports = "Reports",
  createRole = "createRole",
  libraries = "Libraries",
  library = "Library",
  events = "Events",
  eventsID = "EventsID",
  cycleEvents = "cycleEvents",
  event = "Event",
  eventID = "EventID",
  cycleEvent = "cycleEvent",
  logs = "Logs",
  archive = "Archive",
  faq = "Faq",
  cycleEventsID = "cycleEventsID",
  partners = "partners",
  "admin/roles/role" = "admin/roles/role",
  "admin" = "admin",
  "admin/index" = "admin/index",
  "admin/roles" = "admin/roles",
  "admin/library" = "admin/library",
  "admin/archive" = "admin/archive",
  "admin/partners" = "admin/partners",
  "admin/partner-edit" = "admin/partner-edit",
  "admin/partner-create" = "admin/partner-create",
  "noRights" = "noRights",
}

export enum RoleSections {
  USERS = "Пользователи",
  CATALOGS = "Каталоги",
  ADMINISTRATION = "Администрирование",
}

export type Common<A, B> = {
  [P in keyof A & keyof B]: A[P] & B[P];
};
