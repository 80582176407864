import { Component, Vue } from "vue-property-decorator";

@Component
export default class userRolesMixin extends Vue {
  get DELETE_CHAT_MESSAGE(): boolean {
    return this.$store.getters["session/isAllow"]("DELETE_CHAT_MESSAGE");
  }
  get WATCH_EVENT_STATISTIC(): boolean {
    return this.$store.getters["session/isAllow"]("WATCH_EVENT_STATISTIC");
  }
  get VIEW_EVENT_CYCLE(): boolean {
    return this.$store.getters["session/isAllow"]("VIEW_EVENT_CYCLE");
  }
  get VIEW_ARCHIVE_LIST(): boolean {
    return this.$store.getters["session/isAllow"]("VIEW_ARCHIVE_LIST");
  }
  get VIEW_USERS_LIST(): boolean {
    return this.$store.getters["session/isAllow"]("READ_USERS_LIST");
  }
  get EDIT_ROLE(): boolean {
    return this.$store.getters["session/isAllow"]("EDIT_ROLE");
  }
  get ASSIGN_ROLE(): boolean {
    return this.$store.getters["session/isAllow"]("ASSIGN_ROLE");
  }
  get VIEW_ROLES_LIST(): boolean {
    return this.$store.getters["session/isAllow"]("VIEW_ROLES_LIST");
  }
  get DELETE_ROLE(): boolean {
    return this.$store.getters["session/isAllow"]("DELETE_ROLE");
  }
  get VIEW_ROLE(): boolean {
    return this.$store.getters["session/isAllow"]("VIEW_ROLE");
  }
  get CREATE_ROLE(): boolean {
    return this.$store.getters["session/isAllow"]("CREATE_ROLE");
  }
  get CREATE_TITLE_OBJECT(): boolean {
    return this.$store.getters["session/isAllow"]("CREATE_TITLE_OBJECT");
  }
  get EDIT_EVENT_CYCLE(): boolean {
    return this.$store.getters["session/isAllow"]("EDIT_EVENT_CYCLE");
  }
  get CREATE_EVENT_CYCLE(): boolean {
    return this.$store.getters["session/isAllow"]("CREATE_EVENT_CYCLE");
  }
  get DELETE_EVENT_CYCLE(): boolean {
    return this.$store.getters["session/isAllow"]("DELETE_EVENT_CYCLE");
  }
  get VIEW_EVENT(): boolean {
    return this.$store.getters["session/isAllow"]("VIEW_EVENT");
  }
  get EDIT_EVENT(): boolean {
    return this.$store.getters["session/isAllow"]("EDIT_EVENT");
  }
  get CREATE_EVENT(): boolean {
    return this.$store.getters["session/isAllow"]("CREATE_EVENT");
  }
  get DELETE_EVENT(): boolean {
    return this.$store.getters["session/isAllow"]("DELETE_EVENT");
  }
  get ARCHIVE_EVENT(): boolean {
    return this.$store.getters["session/isAllow"]("ARCHIVE_EVENT");
  }
  get CREATE_NMO_POINTS(): boolean {
    return this.$store.getters["session/isAllow"]("CREATE_NMO_POINTS");
  }
  get VIEW_STREAM(): boolean {
    return this.$store.getters["session/isAllow"]("VIEW_STREAM");
  }
  get VIEW_TITLE_OBJECT(): boolean {
    return this.$store.getters["session/isAllow"]("VIEW_TITLE_OBJECT");
  }
  get EDIT_TITLE_OBJECT(): boolean {
    return this.$store.getters["session/isAllow"]("EDIT_TITLE_OBJECT");
  }
  get DELETE_TITLE_OBJECT(): boolean {
    return this.$store.getters["session/isAllow"]("DELETE_TITLE_OBJECT");
  }
  get READ_USER(): boolean {
    return this.$store.getters["session/isAllow"]("READ_USER");
  }
  get CREATE_USER(): boolean {
    return this.$store.getters["session/isAllow"]("CREATE_USER");
  }
  get UPDATE_USER(): boolean {
    return this.$store.getters["session/isAllow"]("UPDATE_USER");
  }
  get DELETE_USER(): boolean {
    return this.$store.getters["session/isAllow"]("DELETE_USER");
  }
  get DEACTIVATE_USER(): boolean {
    return this.$store.getters["session/isAllow"]("DEACTIVATE_USER");
  }
}
