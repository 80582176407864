


























































































































import { Component, Vue, Prop, Emit, VModel } from "vue-property-decorator";
import PasswordInput from "@/components/widgets/commons/Inputs/PasswordInput.vue";
import * as rules from "@/core/validation";

enum Steps {
  Login = 1,
  Password = 2,
}

enum LoginTypes {
  Phone = "phone",
  Email = "email",
}

@Component({
  components: {
    PasswordInput,
  },
})
export default class UserCreationDialog extends Vue {
  @Prop({ type: Boolean, default: false }) loading!: boolean;
  @Prop({ type: [Object, String], default: null }) errorMessage!: string | null;
  @VModel({ type: Boolean, default: false }) visible!: boolean;

  protected readonly rules = rules;
  protected readonly LoginTypes = LoginTypes;
  protected readonly loginType = LoginTypes.Email;
  protected readonly Steps = Steps;
  protected step = Steps.Login;

  protected loginValid: boolean = false;
  protected passwordValid: boolean = false;

  protected password: string = "";
  protected passwordConfirm: string = "";
  protected login: string = "";

  @Emit("create-user")
  protected normalizeAndEmitData(): {
    login: string;
    password: string;
    loginType: LoginTypes;
  } {
    return {
      login: this.login,
      loginType: this.loginType,
      password: this.password,
    };
  }
}
