



































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ControlsBar extends Vue {
  @Prop({ type: Boolean, default: false }) loading!: boolean;
  @Prop({ type: Boolean, default: true }) visible!: boolean;

  protected isVisible: boolean = true;
  public hide(): void {
    this.isVisible = false;
  }
  public show(): void {
    this.isVisible = true;
  }
}
