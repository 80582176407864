import { DateTime } from "luxon";

import { PluginObject } from "vue";

export const date = (value: string): string => {
  return DateTime.fromISO(value).setLocale("ru").toFormat("DD ',' HH:MM") || "";
};

export const currency = (value: number): string => {
  return new Intl.NumberFormat("ru-RU", {
    style: "currency",
    currency: "RUB",
  }).format(+value);
};

export default <PluginObject<void>>{
  install(Vue) {
    Vue.filter("date", date);
    Vue.filter("currency", currency);
  },
};
