var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-lg",attrs:{"rounded":"","elevation":"0"}},[_c('v-card-title',[_vm._v("Места работы")]),_c('div',{staticClass:"shadow-select px-4"},[_c('v-autocomplete',{attrs:{"dense":"","rules":[_vm.rules.requiredList],"items":_vm.workplaces,"value":_vm.workplaces,"hint":"* Обязательно","persistent-hint":"","item-value":"id","item-text":"name","label":"Места работы","return-object":"","multiple":""}})],1),(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"list-item-two-line"}}):(!_vm.workplaces || !_vm.workplaces.length)?_c('v-subheader',[_vm._v(" Не назначено ")]):_c('v-list',_vm._l((_vm.workplaces),function(item,key){return _c('v-list-item',{key:key,on:{"click":function($event){return _vm.assignPrimary(item)}}},[_c('v-list-item-action',[_c('v-checkbox',{model:{value:(item.pivot.is_primary),callback:function ($$v) {_vm.$set(item.pivot, "is_primary", $$v)},expression:"item.pivot.is_primary"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.hospitalNames[item.hospital_type])+" ")])],1)],1)}),1),_c('v-divider'),_c('v-card-actions',[_c('v-dialog',{attrs:{"max-width":"500px","width":"100%","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","color":"primary","text":""},on:{"click":function($event){return _vm.$apollo.queries.allDoctorsWorkplaces.start()}}},on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-pencil ")]),_vm._v(" Изменить ")],1)]}}]),model:{value:(_vm.formVisible),callback:function ($$v) {_vm.formVisible=$$v},expression:"formVisible"}},[_c('v-card',{staticClass:"rounded-lg",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(" Назначить места работы ")]),_c('div',{staticClass:"px-6"},[_c('v-autocomplete',{attrs:{"items":_vm.allDoctorsWorkplaces,"item-value":"id","item-text":"name","label":"Места работы","return-object":"","cache-items":"","chips":"","deletable-chips":"","multiple":"","loading":_vm.$apollo.queries.allDoctorsWorkplaces.loading,"search-input":_vm.search},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"change":function($event){_vm.search = ''}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Места работы "),_c('kbd',[_vm._v(_vm._s(_vm.search))]),_vm._v(" пока не существует. "),_c('v-dialog',{attrs:{"max-width":"400px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","text":"","small":""},on:{"click":function($event){_vm.createWorkplaceModel.name = _vm.search}}},on),[_vm._v(" создать ")])]}}]),model:{value:(_vm.createWorkplaceVisible),callback:function ($$v) {_vm.createWorkplaceVisible=$$v},expression:"createWorkplaceVisible"}},[_c('v-card',[_c('v-card-title',[_vm._v("Создать место работы")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('div',{staticClass:"px-6"},[_c('ValidationProvider',{attrs:{"rules":"required|alpha_dash_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Название клиники","error-messages":errors,"hint":"Обязательно","persistent-hint":""},model:{value:(_vm.createWorkplaceModel.name),callback:function ($$v) {_vm.$set(_vm.createWorkplaceModel, "name", $$v)},expression:"createWorkplaceModel.name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{staticClass:"mb-6",attrs:{"error-messages":errors,"hint":"Обязательно","persistent-hint":""},model:{value:(_vm.createWorkplaceModel.type),callback:function ($$v) {_vm.$set(_vm.createWorkplaceModel, "type", $$v)},expression:"createWorkplaceModel.type"}},_vm._l((_vm.hospitalTypes),function(hospital){return _c('v-radio',{key:hospital.value,attrs:{"label":hospital.text,"value":hospital.value}})}),1)]}}],null,true)})],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","text":"","disabled":!valid || _vm.createWorkplaceProgress,"loading":_vm.createWorkplaceProgress},on:{"click":_vm.createWorkplace}},[_vm._v(" Добавить ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.createWorkplaceVisible = false}}},[_vm._v(" отменить ")])],1)]}}])})],1)],1)],1)],1)],1)]},proxy:true}]),model:{value:(_vm.formWorkplacesValue),callback:function ($$v) {_vm.formWorkplacesValue=$$v},expression:"formWorkplacesValue"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.assignPositions}},[_vm._v(" Назначить ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.formVisible = false}}},[_vm._v(" отмена")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }