/**
 * Проверка наличия всех ключей и значений у объекта
 */
export const isRequired = <T extends Record<string, any>>(
  obj: T
): obj is {
  [P in keyof T]-?: Exclude<T[P], undefined>;
} => {
  return !Object.keys(obj).find((key: keyof typeof obj) => {
    return !(key in obj) || obj[key] === undefined;
  });
};

export const isNonNullable = <T extends Record<string, any>>(
  obj: T
): obj is {
  [P in keyof T]-?: Exclude<T[P], undefined | null>;
} => {
  return !Object.keys(obj).find((key: keyof typeof obj) => {
    return !(key in obj) || obj[key] === undefined || obj[key] === null;
  });
};
