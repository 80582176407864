














































import { Vue, Component, Prop, Watch, VModel } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";

export type TableHeader = DataTableHeader & { hidden?: boolean };

@Component
export default class TableSettings extends Vue {
  @Prop({ type: Array, default: () => [] }) headers!: TableHeader[];
  @VModel({ type: Array, default: () => [] })
  visibleHeaders!: TableHeader[];

  private visible: boolean = false;
  protected internalHeaders: TableHeader[] = [];

  @Watch("headers")
  protected mounted(): void {
    this.reset();
    this.apply();
  }

  protected apply(): void {
    this.visibleHeaders = this.internalHeaders.filter((h) => !h.hidden);
    this.visible = false;
  }

  protected reset(): void {
    this.internalHeaders = JSON.parse(JSON.stringify(this.headers));
  }
}
