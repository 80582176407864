import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import vuetify from "./plugins/vuetify";
import "./plugins/vee-validate";
// @ts-ignore
import Inputmask from "inputmask";
import { Plugin as VueFragment } from "vue-fragment";
import VueApollo from "vue-apollo";
import { apolloClient } from "@/core/apollo";
// @ts-ignore
import YmapPlugin from "vue-yandex-maps";
import filters from "@/core/filters";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import { consoleBuildInfo } from "vue-cli-plugin-build-info/plugin";
import { verify as verifyConfig } from "./plugins/verify-config";

verifyConfig();

Vue.config.productionTip = false;
Vue.use(VueFragment);
Vue.use(VueApollo);
Vue.use(filters);
Vue.use(YmapPlugin, {
  apiKey: process.env.VUE_APP_SECRET_MAPS_KEY,
  debug: process.env.NODE_ENV !== "production",
});

Vue.directive("mask", {
  bind(el, binding) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    Inputmask(binding.value).mask(el.querySelector("input")!);
  },
});
consoleBuildInfo();

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    $query: {
      fetchPolicy: "cache-and-network",
    },
  },
});

new Vue({
  router,
  store,
  vuetify,
  apolloProvider,
  render: (h) => h(App),
}).$mount("#app");
