



























































































































































































import {
  Component,
  Prop,
  PropSync,
  VModel,
  Watch,
} from "vue-property-decorator";
import { Routes } from "@/types/core";
import { EventsLists } from "@/graphql/queries/Events.graphql";
import ListView from "@/core/UI/Views/ListView";
import {
  eventForms,
  eventFormsNames,
  eventStatuses,
  paymentTypes,
} from "@/core/static/dict";
import {
  EventFormEnum,
  EventsQueryVariables,
  EventStatusEnum,
  InputEventsEnum,
  PaymentTypeEnum,
  SqlOperator,
  Event,
  EventsListsQueryVariables,
  SortOrder,
  QueryEventsOrderByColumn,
} from "@/generated/graphql";
import { Debounce } from "vue-debounce-decorator";

import EventsTable from "@components/parts/tables/EventsTable.vue";
import CycleEventsAutocomplete from "@/components/widgets/commons/Inputs/CycleEventsAutocomplete.vue";
import SpecialtiesAutocomplete from "@/components/widgets/commons/Inputs/SpecialtiesAutocomplete.vue";
import { DateTime } from "luxon";
import { merge } from "lodash";
import { TableHeader } from "@components/parts/tables/Settings.vue";

const initialFilter: {
  eventForms: Array<keyof typeof EventFormEnum>;
  search: string;
  cycles: string[];
  categories: number[];
} = {
  eventForms: [],
  search: "",
  cycles: [],
  categories: [],
};

const initialAdditionalFilters: {
  dateFrom: string;
  dateTo: string;
  specialties: string[];
  paymentType: Array<keyof typeof PaymentTypeEnum>;
  eventStatus: Array<keyof typeof EventStatusEnum>;
} = {
  dateFrom: "",
  dateTo: "",
  specialties: [],
  paymentType: [],
  eventStatus: [],
};

@Component({
  components: {
    EventsTable,
    CycleEventsAutocomplete,
    SpecialtiesAutocomplete,
  },
})
export default class EventsList extends ListView<Event> {
  @Prop({ type: Object, default: () => ({}) })
  extraParams!: EventsQueryVariables;

  @Prop({ type: Array, default: () => [] })
  extraHeaders!: TableHeader[];

  @Prop({ type: Boolean, default: false }) noFilters!: boolean;
  @Prop({ type: Boolean, default: false }) showSelect!: boolean;
  @Prop({ type: Boolean, default: false }) standalone!: boolean;
  @Prop({
    type: Array,
    default: () => [
      { column: QueryEventsOrderByColumn.Id, order: SortOrder.Desc },
    ],
  })
  sort: any;

  @VModel({ type: Array, default: () => [] }) selected!: Event[];

  /**
   * Синхронизация данных таблицы наверх
   */
  @PropSync("events", { type: Array, default: () => [] }) eventsSync!: Event[];
  @Watch("list.data")
  private syncData(): void {
    this.eventsSync = this.list?.data || [];
  }

  protected readonly fetchListQuery = EventsLists;
  protected readonly fetchKey: string = "events";
  protected readonly Routes: typeof Routes = Routes;
  protected readonly isArchive = false;

  protected readonly eventForms = eventForms;
  protected readonly eventFormsNames = eventFormsNames;

  protected readonly paymentTypes = paymentTypes;
  protected readonly eventStatuses = eventStatuses;

  protected additionalFiltersVisible = false;
  protected additionalFilter = { ...initialAdditionalFilters };
  protected readonly initialAdditionalFilters = initialAdditionalFilters;

  protected filter = { ...initialFilter };
  protected readonly initialFilter = initialFilter;

  protected get fetchListVariables(): EventsQueryVariables {
    const orderBy: EventsListsQueryVariables["orderBy"] = this.sort;

    const where: EventsListsQueryVariables["where"] = {
      column: InputEventsEnum.IsArchive,
      operator: SqlOperator.Eq,
      value: false,
      AND: [],
    };

    if (this.filter.eventForms.length) {
      where.AND?.push({
        column: InputEventsEnum.EventForm,
        operator: SqlOperator.In,
        value: this.filter.eventForms.map((f: string) => f.toLowerCase()),
      });
    }

    if (this.filter.search?.length > 0) {
      where.AND?.push({
        OR: [
          {
            column: InputEventsEnum.PublicName,
            operator: SqlOperator.Ilike,
            value: `%${this.filter.search}%`,
          },
          {
            column: InputEventsEnum.PrivateName,
            operator: SqlOperator.Ilike,
            value: `%${this.filter.search}%`,
          },
        ],
      });
    }

    // Фильтр мероприятий по одиночным и в цикле
    if (this.filter.categories.length > 0) {
      where.AND?.push({
        OR: [
          this.filter.categories.includes(1)
            ? {
                HAS: {
                  relation: "eventCycles",
                  operator: SqlOperator.Eq,
                  amount: 0,
                },
              }
            : {},

          this.filter.categories.includes(2)
            ? {
                HAS: {
                  relation: "eventCycles",
                  operator: SqlOperator.Gt,
                  amount: 0,
                },
              }
            : {},
        ],
      });
    }

    // Фильтр по циклу мероприятия
    if (this.filter.cycles.length) {
      where.AND?.push({
        HAS: {
          relation: "eventCycles",
          condition: {
            column: InputEventsEnum.Id,
            operator: SqlOperator.In,
            value: this.filter.cycles,
          },
        },
      });
    }

    /* ДОПОЛНИТЕЛЬНЫЕ ФИЛЬТРЫ */
    if (this.additionalFilter.paymentType.length) {
      where.AND?.push({
        column: InputEventsEnum.PaymentType,
        operator: SqlOperator.In,
        value: this.additionalFilter.paymentType.map((p) => p.toLowerCase()),
      });
    }

    if (this.additionalFilter.eventStatus.length) {
      where.AND?.push({
        column: InputEventsEnum.EventStatus,
        operator: SqlOperator.In,
        value: this.additionalFilter.eventStatus.map((s) => s.toLowerCase()),
      });
    }

    if (this.additionalFilter.specialties.length > 0) {
      where.AND?.push({
        HAS: {
          relation: "specialties",
          condition: {
            column: InputEventsEnum.Id,
            operator: SqlOperator.In,
            value: this.additionalFilter.specialties,
          },
        },
      });
    }

    if (this.additionalFilter.dateFrom) {
      where.AND?.push({
        column: InputEventsEnum.DateFrom,
        operator: SqlOperator.Between,
        value: [
          DateTime.fromISO(this.additionalFilter.dateFrom).toISO({
            suppressMilliseconds: true,
            includeOffset: false,
          }),
          DateTime.fromISO(this.additionalFilter.dateFrom)
            .set({ hour: 23, minute: 59, second: 59 })
            .toISO({
              suppressMilliseconds: true,
              includeOffset: false,
            }),
        ],
      });
    }

    if (this.additionalFilter.dateTo) {
      where.AND?.push({
        column: InputEventsEnum.DateTo,
        operator: SqlOperator.Between,
        value: [
          DateTime.fromISO(this.additionalFilter.dateTo).toISO({
            suppressMilliseconds: true,
            includeOffset: false,
          }),
          DateTime.fromISO(this.additionalFilter.dateTo)
            .set({ hour: 23, minute: 59, second: 59 })
            .toISO({
              suppressMilliseconds: true,
              includeOffset: false,
            }),
        ],
      });
    }

    return merge({ where, page: 1, orderBy }, this.extraParams);
  }

  @Watch("filter.search")
  @Debounce(500)
  private search(): void {
    this.refetch();
  }
}
