import { Getters, Mutations, Actions, Module } from "vuex-smart-module";
import { apolloClient } from "@/core/apollo";
import { User } from "@/types/employees";
import { Headers } from "@/types/table";
import { UserQuery, UserBackend } from "@/types/user";
import GQLUsersQuery from "@/graphql/queries/Users.graphql";
import { Commit } from "vuex";

class UsersState {
  headers: Headers = [
    { text: "ID", value: "id" },
    { text: "ФИО пользователя", value: "fio" },
    { text: "Роль", value: "role" },
    { text: "Специальность", value: "specialisation" },
    { text: "Ассоциация врачей", value: "association" },
    { text: "Город", value: "city" },
    { text: "E-mail", value: "email" },
    { text: "Телефон", value: "phone" },
    { text: "", value: "_actions", sortable: false },
  ];
  visibleHeaders: Headers = [...this.headers];
  employees: User[] = [];
  settings: Array<number> = [];
}

class UsersGetters extends Getters<UsersState> {
  get visibleHeaders(): Headers {
    return this.state.visibleHeaders;
  }
  get employees(): User[] {
    return this.state.employees;
  }
  get headers(): Headers {
    return this.state.headers;
  }
  get settings(): Array<number> {
    return this.state.settings;
  }
}

class UsersMutations extends Mutations<UsersState> {
  setVisibleHeaders(settings: Array<number>): void {
    this.state.visibleHeaders = this.state.headers.filter(
      (_, index: number) => !settings.includes(index)
    );
    this.state.settings = [...settings];
  }
  setSettings(settings: Array<number>): void {
    this.state.settings = [...settings];
  }
  setEmployees(employees: User[]): void {
    this.state.employees = [...employees];
  }
}

class UsersActions extends Actions<
  UsersState,
  UsersGetters,
  UsersMutations,
  UsersActions
> {
  async getUsers({ page, first }: UserQuery) {
    const commit: Commit = this.commit;
    try {
      const data = await apolloClient.query({
        query: GQLUsersQuery,
        variables: {
          page,
          first,
        },
      });
      const users: UserBackend[] = data?.data?.users?.data;
      const total: number = data?.data?.users?.paginatorInfo.total;
      if (!users) return [];
      const callback = (acc: string, position: { name: string }) => {
        acc += position.name + " ";
        return acc;
      };
      const callbackContacts: any = (
        acc: string,
        contact: { title: string; value: string }
      ) => {
        acc += contact.title + ": " + contact.value + " ";
        return acc;
      };
      const mappedUser: User[] = users.map((user: UserBackend) => {
        const obj: any = {};
        obj[
          "fio"
        ] = `${user["family_name"]} ${user["first_name"]} ${user["second_name"]}`;
        obj["id"] = user["id"];
        obj["email"] = user["emails"]?.reduce(callbackContacts, "");
        obj["phone"] = user["phones"]?.reduce(callbackContacts, "");
        obj["specialisation"] = user["doctorProfile"]?.specialties?.reduce(
          callback,
          ""
        );
        obj["association"] = user["doctorProfile"]?.associations?.reduce(
          callback,
          ""
        );
        obj["city"] = user["city"];
        return obj;
      });
      commit("setEmployees", mappedUser);
      return { users: mappedUser, total };
    } catch (e) {
      console.error(e);
    }
  }
}

export default new Module({
  state: UsersState,
  mutations: UsersMutations,
  getters: UsersGetters,
  actions: UsersActions,
});
