




















































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import {
  UploadDocumentMutationVariables,
  UserDocumentsQuery,
} from "@/generated/graphql";
import { UserDocuments } from "@/graphql/queries/User.graphql";
import { UploadDocument } from "@/graphql/queries/Applications.graphql";
import Card from "@components/page/Card.vue";
import Download from "@components/Download.vue";
import { Maybe } from "graphql/jsutils/Maybe";

@Component({
  components: { Card, Download },
})
export default class UserDocs extends Vue {
  @Prop({ type: String, required: true }) userId!: string;

  protected readonly UserDocuments = UserDocuments;
  protected readonly UploadMutation = UploadDocument;
  protected readonly headers: Array<DataTableHeader> = [
    { text: "Название", value: "name", sortable: false },
    { text: "Заявка", value: "application", sortable: false },
    { text: "Скачать", value: "url", sortable: false },
  ];

  protected form = {
    file: null,
    name: "",
    comment: "",
    application_id: "",
  };
  protected uploadFormVisible = false;
  protected isValidForm = false;
  protected file: File | null = null;

  protected get uploadVariables(): UploadDocumentMutationVariables {
    return !this.form.application_id
      ? {
          user_id: this.userId,
          comment: this.form.comment,
          file: this.form.file,
        }
      : {
          application_id: this.form.application_id,
          comment: this.form.comment,
          file: this.form.file,
        };
  }

  protected mapDocs(
    data: Maybe<UserDocumentsQuery>
  ): (
    | NonNullable<NonNullable<UserDocumentsQuery["user"]>["documents"]>[number]
    | NonNullable<
        NonNullable<NonNullable<UserDocumentsQuery["user"]>["applications"]>
      >[number]["documents"]
  )[] {
    if (data?.user) {
      const docs = data.user.documents;
      const apps = data.user.applications.map((a) => a.documents).flat();

      if (docs) {
        return [...docs, ...apps];
      }
      return apps || [];
    }
    return [];
  }
}
