import {
  ApplicationStatusEnum,
  ApplicationTypeEnum,
  BlockTypeEnum,
  EventCategoryEnum,
  EventFormEnum,
  EventStatusEnum,
  HospitalTypeEnum,
  NmoStatusEnum,
  ParticipantStatusEnum,
  PaymentTypeEnum,
  TitleObjectStateEnum,
  TitleObjectTypeEnum,
} from "@/generated/graphql";
import { PartnerStatuses } from "@/graphql/queries/Partners.graphql";
import { EventTypes } from "@/types/event";

export declare type StaticDict<T extends unknown = unknown> = Array<{
  value: T;
  text: string;
}>;

export const eventStatusesNames: Record<string, string> = {
  [EventStatusEnum.Awaiting]: "На аккредитации",
  [EventStatusEnum.NotAccredited]: "Не аккредитовано",
  [EventStatusEnum.Accredited]: "Аккредитовано",
};
export const ApplicationStatus: Record<string, string> = {
  [ApplicationStatusEnum.Processing]: "В процессе",
  [ApplicationStatusEnum.Approved]: "Подтвержден",
  [ApplicationStatusEnum.Rejected]: "Отклонен",
};

export const ApplicationType: Record<string, string> = {
  [ApplicationTypeEnum.Lector]: "Заявка на лекторство",
  [ApplicationTypeEnum.Thesis]: "Подача тезиса",
  [ApplicationTypeEnum.Doctor]: "Заявка на статус Доктора",
  [ApplicationTypeEnum.Ticket]: "Обращение в поддержку",
};

export const ApplicationStatuses: StaticDict<ApplicationStatusEnum> = [
  {
    value: ApplicationStatusEnum.Processing,
    text: ApplicationStatus[ApplicationStatusEnum.Processing],
  },
  {
    value: ApplicationStatusEnum.Approved,
    text: ApplicationStatus[ApplicationStatusEnum.Approved],
  },
  {
    value: ApplicationStatusEnum.Rejected,
    text: ApplicationStatus[ApplicationStatusEnum.Rejected],
  },
];
export const eventStatuses: StaticDict<EventStatusEnum> = [
  {
    value: EventStatusEnum.Awaiting,
    text: eventStatusesNames[EventStatusEnum.Awaiting],
  },
  {
    value: EventStatusEnum.NotAccredited,
    text: eventStatusesNames[EventStatusEnum.NotAccredited],
  },
  {
    value: EventStatusEnum.Accredited,
    text: eventStatusesNames[EventStatusEnum.Accredited],
  },
];

export const hospitalNames: Record<string, string> = {
  [HospitalTypeEnum.Private]: "Частный центр",
  [HospitalTypeEnum.Public]: "Государственное учреждение",
};

export const hospitalTypes: StaticDict = [
  { value: HospitalTypeEnum.Private, text: "Частный центр" },
  { value: HospitalTypeEnum.Public, text: "Государственное учреждение" },
];

export const eventFormsNames: Record<string, string> = {
  [EventFormEnum.Offline]: "Очное",
  [EventFormEnum.Online]: "Заочное",
  [EventFormEnum.Mixed]: "Очно-заочное",
};

export const eventForms: StaticDict<EventFormEnum> = [
  {
    value: EventFormEnum.Offline,
    text: eventFormsNames[EventFormEnum.Offline],
  },
  { value: EventFormEnum.Online, text: eventFormsNames[EventFormEnum.Online] },
  { value: EventFormEnum.Mixed, text: eventFormsNames[EventFormEnum.Mixed] },
];

export const eventCategoriesNames: Record<string, string> = {
  [EventCategoryEnum.Congress]: "Конгресс",
  [EventCategoryEnum.Cycle]: "Цикл",
  [EventCategoryEnum.School]: "Школа",
};

export const eventCategories: StaticDict<EventCategoryEnum> = [
  {
    value: EventCategoryEnum.Congress,
    text: eventCategoriesNames[EventCategoryEnum.Congress],
  },
  {
    value: EventCategoryEnum.Cycle,
    text: eventCategoriesNames[EventCategoryEnum.Cycle],
  },
  {
    value: EventCategoryEnum.School,
    text: eventCategoriesNames[EventCategoryEnum.School],
  },
];

export const paymentTypes: StaticDict<PaymentTypeEnum> = [
  { value: PaymentTypeEnum.Free, text: "Бесплатно" },
  { value: PaymentTypeEnum.Paid, text: "Платно" },
];

export const paymentTypesNames: Record<string, string> = {
  [PaymentTypeEnum.Free]: "Бесплатно",
  [PaymentTypeEnum.Paid]: "Платно",
};

export const additionalBlocksTypes: StaticDict<BlockTypeEnum> = [
  { value: BlockTypeEnum.Image, text: "Изображение" },
  { value: BlockTypeEnum.Video, text: "Видео" },
  { value: BlockTypeEnum.Text, text: "Текст" },
];

export const categoryEventCycle: StaticDict<EventCategoryEnum> = [
  { value: EventCategoryEnum.Congress, text: "Конгресс" },
  { value: EventCategoryEnum.School, text: "Школа" },
  { value: EventCategoryEnum.Cycle, text: "Цикл" },
];

export const TitleObjectType: Record<string, string> = {
  [TitleObjectTypeEnum.Notification]: "Уведомление",
  [TitleObjectTypeEnum.Poll]: "Опрос",
  [TitleObjectTypeEnum.Presence]: "Присутствие",
  [TitleObjectTypeEnum.Welcome]: "Приветствие",
  [TitleObjectTypeEnum.Question]: "Вопрос",
};

export const TitleObjectState: Record<string, string> = {
  [TitleObjectStateEnum.Done]: "Выполнен",
  [TitleObjectStateEnum.Queued]: "В очереди",
  [TitleObjectStateEnum.Started]: "Запущен",
};

export const StatisticsStatus: Record<string, string> = {
  [ParticipantStatusEnum.Registered]: "Зарегистрирован",
  [ParticipantStatusEnum.Paid]: "Оплачен",
  [ParticipantStatusEnum.Attended]: "Участвовал",
  [ParticipantStatusEnum.Passed]: "Прошел",
};

// Статусы заявок
export const applicationStatusNames: Record<string, string> = {
  [ApplicationStatusEnum.Processing]: "Обрабатывается",
  [ApplicationStatusEnum.Approved]: "Принято",
  [ApplicationStatusEnum.Rejected]: "Отклонено",
};
export const applicationStatuses: StaticDict<ApplicationStatusEnum> = [
  {
    value: ApplicationStatusEnum.Processing,
    text: applicationStatusNames[ApplicationStatusEnum.Processing],
  },
  {
    value: ApplicationStatusEnum.Approved,
    text: applicationStatusNames[ApplicationStatusEnum.Approved],
  },
  {
    value: ApplicationStatusEnum.Rejected,
    text: applicationStatusNames[ApplicationStatusEnum.Rejected],
  },
];

// Типы доп. Блоков
export const blockTypeNames: Record<string, string> = {
  [BlockTypeEnum.Text]: "текст",
  [BlockTypeEnum.Image]: "Изображение",
  [BlockTypeEnum.Video]: "Видео",
};
export const blockTypes: StaticDict<BlockTypeEnum> = [
  { value: BlockTypeEnum.Text, text: blockTypeNames[BlockTypeEnum.Text] },
  { value: BlockTypeEnum.Image, text: blockTypeNames[BlockTypeEnum.Image] },
  { value: BlockTypeEnum.Video, text: blockTypeNames[BlockTypeEnum.Video] },
];

// Типы заявок
export const applicationTypeNames: Record<string, string> = {
  [ApplicationTypeEnum.Thesis]: "Подача тезиса",
  [ApplicationTypeEnum.Doctor]: "Заявка на доктора",
  [ApplicationTypeEnum.Lector]: "Заявка на лекторство",
  [ApplicationTypeEnum.Ticket]: "Обращение в техподдержку",
};
export const applicationTypes: StaticDict<ApplicationTypeEnum> = [
  {
    value: ApplicationTypeEnum.Thesis,
    text: applicationTypeNames[ApplicationTypeEnum.Thesis],
  },
  {
    value: ApplicationTypeEnum.Doctor,
    text: applicationTypeNames[ApplicationTypeEnum.Doctor],
  },
  {
    value: ApplicationTypeEnum.Lector,
    text: applicationTypeNames[ApplicationTypeEnum.Lector],
  },
  {
    value: ApplicationTypeEnum.Ticket,
    text: applicationTypeNames[ApplicationTypeEnum.Ticket],
  },
];

// Статусы НМО
export const nmoStatusesNames: Record<string, string> = {
  [NmoStatusEnum.Credited]: "Начислено",
  [NmoStatusEnum.Reserve]: "Резервный",
};
export const nmoStatuses: StaticDict<NmoStatusEnum> = [
  {
    value: NmoStatusEnum.Credited,
    text: nmoStatusesNames[NmoStatusEnum.Credited],
  },
  {
    value: NmoStatusEnum.Reserve,
    text: nmoStatusesNames[NmoStatusEnum.Reserve],
  },
];

export const participantStatusNames: Record<string, string> = {
  [ParticipantStatusEnum.Paid]: "Оплачено",
  [ParticipantStatusEnum.Registered]: "Зарегистрирован",
  [ParticipantStatusEnum.Attended]: "Присутствовал",
  [ParticipantStatusEnum.Passed]: "Прошел",
};

export const participantStatuses: StaticDict<ParticipantStatusEnum> = [
  {
    value: ParticipantStatusEnum.Paid,
    text: participantStatusNames[ParticipantStatusEnum.Paid],
  },
  {
    value: ParticipantStatusEnum.Registered,
    text: participantStatusNames[ParticipantStatusEnum.Registered],
  },
  {
    value: ParticipantStatusEnum.Attended,
    text: participantStatusNames[ParticipantStatusEnum.Attended],
  },
  {
    value: ParticipantStatusEnum.Passed,
    text: participantStatusNames[ParticipantStatusEnum.Passed],
  },
];
