


















































































import {
  Component,
  Emit,
  Prop,
  VModel,
  Vue,
  Watch,
} from "vue-property-decorator";
import {
  CreateDoctorAssociationMutation,
  CreateDoctorAssociationMutationVariables,
  DoctorAssociation,
  SyncInput,
} from "@/generated/graphql";
import {
  AllDoctorAssociations,
  createDoctorAssociation,
} from "@/graphql/queries/DoctorsAssociations.graphql";

@Component({
  apollo: {
    allDoctorsAssociations: {
      query: AllDoctorAssociations,
      skip: true,
    },
  },
})
export default class Associations extends Vue {
  @VModel({ type: Array, default: () => [] })
  associations!: DoctorAssociation[];
  @Prop({ type: Boolean, default: false }) loading!: boolean;

  protected formVisible = false;
  protected allDoctorsAssociations: DoctorAssociation[] = [];

  protected formAssociationValue: DoctorAssociation[] = [];

  @Watch("associations", { immediate: true })
  protected syncAssociations(): void {
    this.formAssociationValue = this.associations;
  }
  protected assignAssociations(): void {
    this.associations = this.formAssociationValue;
    this.formVisible = false;
  }

  protected createAssociationProgress = false;
  protected search: string = "";
  protected async createAssociation(): Promise<void> {
    try {
      this.createAssociationProgress = true;
      await this.$apollo.mutate<
        CreateDoctorAssociationMutation,
        CreateDoctorAssociationMutationVariables
      >({
        mutation: createDoctorAssociation,
        variables: { name: this.search },
      });
      await this.$apollo.queries.allDoctorsAssociations.refetch();
    } catch (e) {
      console.error(e);
    } finally {
      this.createAssociationProgress = false;
    }
  }
}
