





























































































import { Vue, Component, Prop, VModel } from "vue-property-decorator";
import { imageExtension } from "@/core/validation";

@Component({
  name: "UserAvatar",
})
export default class extends Vue {
  @Prop({ type: String }) alt?: string;
  @VModel({ type: [String, File] }) avatar!: string | File | null;

  public beforeDestroy(): void {
    this.avatarCache && URL.revokeObjectURL(this.avatarCache);
    this.previewCache && URL.revokeObjectURL(this.previewCache);
  }

  protected readonly rules = [imageExtension];
  protected avatarValid = false;
  protected notify = false;
  protected attachAvatarDialogVisible = false;
  protected confirmDelete = false;
  protected file: File | null = null;

  private previewCache = "";
  protected get avatarPreview(): string | null {
    if (this.file instanceof File && this.avatarValid) {
      return (this.previewCache = URL.createObjectURL(this.file));
    } else {
      return this.avatar instanceof File
        ? (this.previewCache = URL.createObjectURL(this.avatar))
        : this.avatar;
    }
  }

  private avatarCache = "";
  protected get avatarUrl(): string | null {
    return this.avatar instanceof File
      ? (this.avatarCache = URL.createObjectURL(this.avatar))
      : this.avatar;
  }

  protected applyAvatar(): void {
    this.avatar = this.file;
    this.notify = true;
    this.attachAvatarDialogVisible = false;
  }

  private async _deleteAvatar(): Promise<void> {
    this.confirmDelete = false;
    this.avatar = null;
  }
}
