











































































import {
  Component,
  Emit,
  Prop,
  VModel,
  Vue,
  Watch,
} from "vue-property-decorator";
import {
  AllDoctorSpecialities,
  createDoctorSpecialty,
} from "@/graphql/queries/DoctorsSpecialities.graphql";
import {
  CreateDoctorPositionMutation,
  CreateDoctorPositionMutationVariables,
  DoctorSpecialty,
  EmployeeSpecialty,
  QueryAllDoctorsSpecialtiesOrderByColumn,
  SortOrder,
  SyncInput,
} from "@/generated/graphql";
import {
  AllEmployeesSpecialties,
  createEmployeeSpecialty,
} from "@/graphql/queries/EmployeesSpecialities.graphql";
import { rules, ruMessages } from "@/plugins/vee-validate";

@Component({
  apollo: {
    allDoctorsSpecialties: {
      query: AllDoctorSpecialities,
      variables() {
        return {
          orderBy: [
            {
              column: QueryAllDoctorsSpecialtiesOrderByColumn.Name,
              order: SortOrder.Asc,
            },
          ],
        };
      },
      skip: true,
    },
    allEmployeesSpecialties: {
      query: AllEmployeesSpecialties,
      skip: true,
    },
  },
})
export default class Specialities extends Vue {
  @VModel({ type: Array, default: () => [] }) items!: Array<
    DoctorSpecialty | EmployeeSpecialty
  >;
  @Prop({ type: Boolean, default: false }) loading!: boolean;
  @Prop({ type: Boolean, default: false }) isEmployee!: boolean;

  protected formVisible = false;
  protected allDoctorsSpecialties: DoctorSpecialty[] = [];
  protected allEmployeesSpecialties: EmployeeSpecialty[] = [];
  protected loadSpecialties(): void {
    this.isEmployee
      ? this.$apollo.queries.allEmployeesSpecialties.start()
      : this.$apollo.queries.allDoctorsSpecialties.start();
  }
  protected get specialities(): Array<DoctorSpecialty | EmployeeSpecialty> {
    return this.isEmployee
      ? this.allEmployeesSpecialties
      : this.allDoctorsSpecialties;
  }
  protected get specialitiesLoading(): boolean {
    return this.isEmployee
      ? this.$apollo.queries.allEmployeesSpecialties.loading
      : this.$apollo.queries.allDoctorsSpecialties.loading;
  }

  protected formSpecialitiesValue: Array<DoctorSpecialty | EmployeeSpecialty> =
    [];

  @Watch("items", { immediate: true })
  protected syncPositions(): void {
    this.formSpecialitiesValue = this.items;
  }
  protected assignPositions(): void {
    this.items = this.formSpecialitiesValue;
    this.formVisible = false;
  }

  protected createSpecialtyProgress = false;
  protected search: string = "";
  protected async createSpecialty(): Promise<void> {
    try {
      this.createSpecialtyProgress = true;
      await this.$apollo.mutate<
        CreateDoctorPositionMutation,
        CreateDoctorPositionMutationVariables
      >({
        mutation: this.isEmployee
          ? createEmployeeSpecialty
          : createDoctorSpecialty,
        variables: { name: this.search },
      });

      this.isEmployee
        ? await this.$apollo.queries.allEmployeesSpecialties.refetch()
        : await this.$apollo.queries.allDoctorsSpecialties.refetch();
    } catch (e) {
      console.error(e);
    } finally {
      this.createSpecialtyProgress = false;
    }
  }
}
