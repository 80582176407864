







import { Vue, Component, VModel } from "vue-property-decorator";

@Component({
  name: "UserComment",
})
export default class Roles extends Vue {
  @VModel({ type: String }) comment!: string;
}
