import * as veeValidateRules from "vee-validate/dist/rules";
import {
  extend,
  localize,
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";
import ru from "@/core/static/messages";
import Vue from "vue";
import { merge } from "lodash";
import {
  ValidationRule,
  ValidationRuleSchema,
} from "vee-validate/dist/types/types";

export const rules = merge<
  Record<string, never>,
  typeof veeValidateRules,
  {
    age: ValidationRule;
    email: ValidationRule;
    alpha_dash_spaces: ValidationRuleSchema;
    youtubeLink: ValidationRuleSchema;
    url: ValidationRuleSchema;
  }
>({}, veeValidateRules, {
  age: {
    validate(value, params) {
      const age = "age" in params && params.age;
      const year = new Date(value).getFullYear();
      const nowYear = new Date().getFullYear();
      return (
        Math.abs(nowYear - year) > +age || "Возраст должен быть больше 18 лет"
      );
    },
    params: ["age"],
  },
  youtubeLink: {
    message() {
      return "inco";
    },
    validate(value: string): boolean {
      return /^http(s)?:\/\/(www\.)?(youtube.com|youtu.be)\/(watch\?v=)?.+$/.test(
        value
      );
    },
  },
  url: {
    message() {
      return "Некорректное адрес сайта";
    },
    validate(value: string): boolean {
      return /https?:\/\/(?:[-\w]+\.)?([-\w]+)\.\w+(?:\.\w+)?\/?.*/i.test(
        value
      );
    },
  },
  email: {
    validate(value: string | string[], { multiple }: Record<string, any> = {}) {
      const defaultValidate = veeValidateRules.email.validate(value, {
        multiple,
      });

      if (!defaultValidate) {
        return defaultValidate;
      }

      const re = /^.{0,64}@.{1,63}$/;

      if (multiple && !Array.isArray(value)) {
        value = String(value)
          .split(",")
          .map((emailStr) => emailStr.trim());
      }

      if (Array.isArray(value)) {
        return value.every((val) => re.test(String(val)));
      }

      return re.test(String(value));
    },
  },
  alpha_dash_spaces: {
    validate(value: string | string[]): boolean {
      const re = /^(\d|\w|[а-яА-Я]|\s)*$/;

      if (Array.isArray(value)) {
        return value.every((val) => re.test(String(val)));
      }

      return re.test(String(value));
    },
  },
});

for (const [rule, validation] of Object.entries(rules)) {
  extend(rule, validation);
}
// extend("imageExtension", imageExtension);

export const ruMessages = merge({}, ru, {
  messages: {
    alpha: (_: any, { locale = "" }) => {
      const before = "Поле может содержать только ";
      switch (locale) {
        case "en":
          return before + "латиницу";
        case "ru":
          return before + "кириллицу";
        default:
          return ru.messages.alpha;
      }
    },
    alpha_dash: (_: any, { locale = "" }) => {
      const before = "Поле может содержать только цифры, дефис и ";
      switch (locale) {
        case "en":
          return before + "латиницу";
        case "ru":
          return before + "кириллицу";
        default:
          return ru.messages.alpha_dash;
      }
    },
    alpha_spaces: (_: any, { locale = "" }) => {
      const before = "Поле может содержать только ";
      const after = " и пробелы";
      switch (locale) {
        case "en":
          return before + "латиницу" + after;
        case "ru":
          return before + "кириллицу" + after;
        default:
          return ru.messages.alpha_spaces;
      }
    },
    alpha_num: (_: any, { locale = "" }) => {
      const before = "Поле может содержать только ";
      const after = " и цифры";
      switch (locale) {
        case "en":
          return before + "латиницу" + after;
        case "ru":
          return before + "кириллицу" + after;
        default:
          return ru.messages.alpha_num;
      }
    },
    alpha_dash_spaces: "Поле может содержать только буквы цифры и пробелы",
  },
});

localize("ru", ruMessages);

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
