








































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { DateTime } from "luxon";

@Component
export default class DatePicker extends Vue {
  @Prop({ type: String, required: true })
  public readonly value!: string;

  @Prop({ type: Boolean, default: true }) clearable!: boolean;

  @Prop({ type: Boolean, default: true }) hideDetails!: boolean;

  @Prop({ type: Boolean, default: true }) readonly!: boolean;

  @Prop({ type: String, default: "Дата" }) label!: string;

  @Prop({ default: "yyyy-MM-dd" })
  public readonly dateFormat!: string;

  @Prop({ type: Boolean, default: false })
  public readonly noFutureDate!: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly onlyFullAge!: boolean;

  @Prop({ type: Array, default: () => [] })
  public readonly rules!: Array<(value: string) => boolean | string>;

  @Prop({ type: Boolean, default: true })
  public readonly required!: boolean;

  private createdDate = DateTime.now()
    .minus({ years: 18 })
    .toFormat("yyyy-MM-dd");

  public get date(): string {
    return this.value ? this.value : this.createdDate;
  }
  public set date(value: string) {
    this.$emit("input", value);
  }

  public get dateFormatted(): string {
    if (this.isValidDate(this.value)) {
      return DateTime.fromISO(this.value).toFormat(this.dateFormat);
    }
    return this.value ? this.value : "";
  }

  public dateRules: Array<(value: string) => boolean | string> = [
    (value: string): boolean | string => {
      if (this.required && !value) {
        return "Обязательно для заполнения";
      } /* else {
        return (
          (this.isValidDate(value) &&
            +DateTime.fromISO(value).toFormat("yyyy") >= 1900) ||
          (!this.required && !value) ||
          "Неверный формат даты"
        );
      }*/
      return true;
    },
  ];

  public isPickerVisible: boolean = false;

  public allowedDates: (value: string, format: string) => boolean = () => true;

  public isValidDate(date: string): boolean {
    if (date && date.replace(/\D/g, "").length === 8) {
      return DateTime.fromISO(date).isValid;
    }
    return false;
  }

  public created(): void {
    if (this.noFutureDate) {
      this.allowedDates = (value: string) => {
        return DateTime.fromISO(value).toLocal() < DateTime.now().toLocal();
      };
      this.dateRules.push((value: string) => {
        if (this.isValidDate(value)) {
          return DateTime.fromISO(value).toLocal() < DateTime.now().toLocal();
        }
        return true;
      });
    }

    if (this.onlyFullAge) {
      this.allowedDates = (value: string) => {
        return DateTime.fromISO(value).plus({ years: 18 }) <= DateTime.now();
      };
      this.dateRules.push((value: string) => {
        if (this.isValidDate(value)) {
          return DateTime.fromISO(value).plus({ years: 18 }) <= DateTime.now();
        }
        return true;
      });
    }

    this.dateRules.push(...this.rules);
  }
}
