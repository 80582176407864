






































































import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import { SexEnum, User } from "@/generated/graphql";
import DatePicker from "@/components/widgets/commons/Inputs/DatePicker.vue";
import { alpha, max, min, required } from "@/core/validation";
import CityAutocomplete from "@/components/widgets/commons/Inputs/CityAutocomplete.vue";

@Component({
  components: {
    CityAutocomplete,
    DatePicker,
  },
})
export default class UserAbout extends Vue {
  @PropSync("family_name", { type: String, required: true })
  syncedFamilyName!: User["family_name"];

  @PropSync("first_name", { type: String, required: true })
  syncedFirstName!: User["first_name"];

  @PropSync("second_name", {
    type: [String],
    default: () => undefined,
  })
  syncedSecondName!: User["second_name"];

  @PropSync("birth_date", {
    type: [String],
    default: () => undefined,
  })
  syncedBD!: User["birth_date"];

  @PropSync("city", {
    type: [Object],
    default: () => undefined,
  })
  syncedCity!: User["city"];

  @PropSync("sex", {
    type: [String, Number],
    default: () => undefined,
  })
  syncedSex!: User["sex"];

  @Prop({ type: Boolean, default: false }) loading!: boolean;

  readonly SexEnum = SexEnum;
  readonly rules = {
    required,
    min,
    max,
    alpha,
  };
}
