import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { Routes } from "@/types/core";
import { store } from "../store";
import user from "../views/users/user.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: Routes.index,
    redirect: { name: Routes["users"] },
  },
  {
    path: "/users",
    name: Routes["users"],
    component: () => import("../views/users/index.vue"),
  },
  {
    path: "/employees",
    name: Routes["employees"],
    component: () => import("../views/employees/index.vue"),
  },
  {
    path: "/employee",
    name: Routes["employee"],
    component: () => import("../views/employees/employee.vue"),
  },
  {
    path: "/employee/:id",
    name: Routes["employeeID"],
    component: () => import("../views/employees/employee.vue"),
  },
  {
    path: "/login",
    name: Routes["login"],
    component: () => import("../views/login.vue"),
  },
  {
    path: "/reset",
    name: Routes["reset"],
    component: () => import("../views/reset.vue"),
  },
  {
    path: "/user",
    name: Routes["user"],
    component: user,
  },
  {
    path: "/user/:id",
    name: Routes["userID"],
    component: () => import("../views/users/user.vue"),
  },
  {
    path: "/cycle-events",
    name: Routes["cycleEvents"],
    component: () => import("../views/events/cycleEvents.vue"),
  },
  {
    path: "/cycle-event/:id",
    name: Routes["cycleEventsID"],
    component: () => import("../views/events/cycleEvent.vue"),
  },
  {
    path: "/events",
    name: Routes.events,
    component: () => import("../views/events/events.vue"),
  },
  {
    path: "/events/:id",
    name: Routes["eventsID"],
    component: () => import("../views/events/events.vue"),
  },
  {
    path: "/lector-question/event/:id",
    component: () => import("../views/events/lectorQuestionTable.vue"),
  },
  {
    path: "/event/:id",
    name: Routes["eventID"],
    component: () => import("../views/events/event.vue"),
  },
  {
    path: "/event",
    name: Routes["event"],
    component: () => import("../views/events/event.vue"),
  },
  {
    path: "/noRights",
    name: Routes["noRights"],
    component: () =>
      import(/* webpackChunkName: "event" */ "../views/NoRights.vue"),
  },
  {
    path: "/cycle-event",
    name: Routes["cycleEvent"],
    component: () => import("../views/events/cycleEvent.vue"),
  },
  {
    path: "/reports",
    name: Routes["reports"],
    component: () => import("../views/reports/index.vue"),
  },
  {
    name: "notFound",
    path: "*",
    component: () => import("../views/notFound.vue"),
  },

  // ADMIN ROUTES
  {
    path: "/admin",
    component: () => import("../views/admin/index.vue"),
    children: [
      {
        path: "",
        name: Routes["admin/index"],
        component: () => import("../views/admin/applications.vue"),
      },
      {
        path: "roles",
        name: Routes["admin/roles"],
        component: () => import("../views/admin/roles.vue"),
      },
      {
        path: "roles/role/:id",
        name: Routes["admin/roles/role"],
        component: () => import("../views/roles/role.vue"),
      },
      {
        path: "roles/createRole",
        name: Routes["createRole"],
        component: () => import("../views/roles/role.vue"),
      },
      {
        path: "library",
        name: Routes["admin/library"],
        component: () => import("../views/admin/library.vue"),
      },
      {
        path: "archive",
        name: Routes["admin/archive"],
        component: () => import("../views/admin/archive.vue"),
      },
      {
        path: "partners",
        name: Routes["admin/partners"],
        component: () => import("../views/admin/partners/partners-list.vue"),
      },
      {
        path: "partner/:id",
        name: Routes["admin/partner-edit"],
        component: () => import("../views/admin/partners/partner-edit.vue"),
      },
      {
        path: "partner-create",
        name: Routes["admin/partner-create"],
        component: () => import("../views/admin/partners/partner-create.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeResolve(async (to, from, next) => {
  try {
    if (!store.getters["session/logged"]) await store.dispatch("session/getMe");
  } catch (e) {
    // @ts-ignore
    const errorMessage = e?.message;
    if (errorMessage.includes("Unauthenticated") && to.name !== Routes.login) {
      return next({ name: Routes.login });
    }
  }

  next();
});

export default router;
