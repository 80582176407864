
































































import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import { Debounce } from "vue-debounce-decorator";
import {
  CycleEventsSelectQueryVariables,
  Event,
  InputCycleEventsEnum,
  SqlOperator,
  UserPaginator,
} from "@/generated/graphql";
import Card from "@components/page/Card.vue";
import { CycleEventsSelect } from "@/graphql/queries/CycleEventsSelectQuery.graphql";

@Component({
  components: { Card },
  apollo: {
    cycleEvents: {
      query: CycleEventsSelect,
      variables(): CycleEventsSelectQueryVariables {
        return {
          page: 1,
        };
      },
    },
  },
})
export default class CycleEventsAutocomplete extends Vue {
  @Prop({ type: Boolean, default: false }) returnObject!: boolean;
  @Prop({ type: [String, Function], default: "public_name" })
  itemText!: string | CallableFunction;

  @Prop({ type: [String, Function], default: "id" })
  itemValue!: string | CallableFunction;

  @VModel({ type: [Object, Array] }) model!: Event;

  protected selected: Event | Event[] | null = null;
  protected searchValue = "";
  protected usersPickerVisible = false;
  protected cycleEvents: UserPaginator = {
    paginatorInfo: {
      count: 0,
      currentPage: 1,
      perPage: 10,
      hasMorePages: true,
      lastPage: 0,
      total: 0,
    },
    data: [],
  };

  protected fetchMoreCycles(): void {
    this.$apollo.queries.cycleEvents.fetchMore({
      //@ts-ignore
      variables: {
        ...this.fetchVariables,
        page: this.cycleEvents.paginatorInfo.currentPage++,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return {
          cycleEvents: {
            __typename: fetchMoreResult.cycleEvents.__typename,
            paginatorInfo: fetchMoreResult.cycleEvents.paginatorInfo,
            data: [
              ...this.cycleEvents.data,
              ...fetchMoreResult.cycleEvents.data,
            ],
          },
        };
      },
    });
  }

  protected get fetchVariables(): CycleEventsSelectQueryVariables {
    const searchValue = this.searchValue ? String(this.searchValue) : "";
    return {
      page: this.cycleEvents.paginatorInfo.currentPage,
      where: {
        OR: [
          {
            column: InputCycleEventsEnum.PublicName,
            value: `%${searchValue}%`,
            operator: SqlOperator.Ilike,
          },
          {
            column: InputCycleEventsEnum.PrivateName,
            value: `%${searchValue}%`,
            operator: SqlOperator.Ilike,
          },
        ],
      },
    };
  }

  @Debounce(500)
  protected search(): void {
    this.$apollo.queries.cycleEvents.refetch({
      ...this.fetchVariables,
      page: 1,
    });
  }
}
