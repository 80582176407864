





























import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import { User } from "@/generated/graphql";

import Card from "@components/page/Card.vue";
import UserPositions from "@widgets/user/UserPositions.vue";
import UserSpeciality from "@widgets/user/UserSpecialty.vue";
import UserWorkplaces from "@widgets/user/UserWorkplaces.vue";
import UserAssociations from "@widgets/user/UserAssociations.vue";

@Component({
  components: {
    Card,
    UserWorkplaces,
    UserAssociations,
    UserPositions,
    UserSpeciality,
  },
})
export default class extends Vue {
  // todo: декомпозировать на отдельные пропсы. для улучшения прозрачности взаимодействия компонентов
  @VModel({}) user!: User;
  @Prop({ type: Boolean, default: false }) loading!: boolean;
}
