














import { Component, Mixins, PropSync } from "vue-property-decorator";
import { User } from "@/generated/graphql";

import UserAvatar from "@widgets/user/UserAvatar.vue";
import UserQRCode from "@widgets/user/UserQRCode.vue";
import UserRoles from "@widgets/user/UserRoles.vue";
import Card from "@components/page/Card.vue";
import userRolesMixin from "@/mixins/userRoles";

@Component({
  components: {
    UserAvatar,
    UserQRCode,
    UserRoles,
    Card,
  },
})
export default class UserCard extends Mixins(userRolesMixin) {
  @PropSync("avatar", { type: [String, File], default: () => undefined })
  syncAvatar!: User["avatar"] | File;

  @PropSync("roles", { type: [Array], default: () => undefined })
  syncRoles!: User["roles"];
}
